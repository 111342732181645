// Plano.js
import React, { useEffect, useMemo } from 'react';
import MapComponent from './pages/mapa/Mapa';

const Plano = ({ coordinates }) => {
  useEffect(() => {
    console.log('Plano.js - Coordenadas actualizadas:', coordinates.x, coordinates.y);
  }, [coordinates]);

  const mapComponent = useMemo(() => {
    console.log('Plano.js - Renderizando MapComponent con coordenadas:', coordinates.x, coordinates.y);
    return <MapComponent xCoord={coordinates.x || 0} yCoord={coordinates.y || 0} />;
  }, [coordinates]);

  return (
    <div>
      <h1>Mapa con React y Leaflet</h1>
      {mapComponent}
    </div>
  );
};

export default Plano;
