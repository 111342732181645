import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import planoTaller from '../../../img/PlanoZonas.png';

// Asegúrate de que estas importaciones son correctas y apuntan a los recursos adecuados
import mecanicoIcon from '../../../img/mecanico.png';
import carroIcon from '../../../img/carroHerramientas.png';
import aceiteIcon from '../../../img/aspiradorAceite.png';
import markerIcon from '../../../img/marker-icon.png';

const Mapa2 = ({ tagsData }) => {
  const mapRef = useRef(null);
  const markersRef = useRef({});

  useEffect(() => {
    if (!mapRef.current) {
      const crs = L.CRS.Simple;
      mapRef.current = L.map('map-container', { crs, minZoom: -2, maxZoom: 2 });
      const bounds = [[0, 0], [1024, 2106]];
      L.imageOverlay(planoTaller, bounds).addTo(mapRef.current);
      mapRef.current.fitBounds(bounds);
    }
  }, []);

  useEffect(() => {
    console.log(tagsData)
    Object.keys(tagsData).forEach(tagId => {
      const { x, y, iconUrl, nombre } = tagsData[tagId];
      if (Number.isFinite(x) && Number.isFinite(y) && iconUrl && mapRef.current) {
        const customIcon = determineIcon(iconUrl);
        const leafletX = ((x - -10500) / (14000 - -10500)) * (2106 - 0);
        const leafletY = ((y - -7000) / (5000 - -7000)) * (1024 - 0);
        const latLng = L.latLng([leafletY, leafletX]);
        addOrUpdateMarker(tagId, latLng.lat, latLng.lng, customIcon, nombre);
      }
    });
  }, [tagsData]); // Dependencia de tagsData

  const determineIcon = (iconUrl) => {
    let icon;
    switch (iconUrl) {
      case 'mecanico':
        icon = mecanicoIcon;
        break;
      case 'carro':
        icon = carroIcon;
        break;
        case 'aceite':
          icon = aceiteIcon;
          break;
      default:
        icon = markerIcon; // Icono por defecto si no hay coincidencia
        break;
    }
    return L.icon({
      iconUrl: icon,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
    });
  };

  // const addOrUpdateMarker = (tagId, lat, lng, customIcon, nombre) => {
  //   if (markersRef.current[tagId]) {
  //     markersRef.current[tagId].setLatLng([lat, lng]).setIcon(customIcon);
  //     markersRef.current[tagId].bindPopup(nombre);
  //   } else {
  //     const marker = L.marker([lat, lng], { icon: customIcon }).bindPopup(nombre).addTo(mapRef.current);
  //     markersRef.current[tagId] = marker;
  //   }
  // };
  const addOrUpdateMarker = (tagId, lat, lng, customIcon, nombre) => {
    const threshold = 50; // Diferencia mínima necesaria para actualizar el mapa

    if (markersRef.current[tagId]) {
        const oldLat = markersRef.current[tagId].getLatLng().lat;
        const oldLng = markersRef.current[tagId].getLatLng().lng;

        if (Math.abs(oldLat - lat) < threshold && Math.abs(oldLng - lng) < threshold) {
            return;
        }

        markersRef.current[tagId].setLatLng([lat, lng]).setIcon(customIcon);
        markersRef.current[tagId].bindPopup(nombre);
    } else {
        const marker = L.marker([lat, lng], { icon: customIcon }).bindPopup(nombre).addTo(mapRef.current);
        markersRef.current[tagId] = marker;
    }
};


  return <div id="map-container" style={{ width: '100%', height: '600px' }} />;
};

export default Mapa2;

