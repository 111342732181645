import React, { useEffect, useState } from 'react';
import mqtt from 'mqtt';
import Mapa2 from './Mapa2'; // Asegúrate de que Mapa2 está adaptado para manejar múltiples marcadores
import { Global } from '../../../helpers/Global'; // Configuración global para URL de la API

const Plano = () => {
  // Estado para almacenar datos de múltiples marcadores
  const [tagsData, setTagsData] = useState({});

  // Función para obtener datos adicionales del tag desde la API
  const fetchTagDetails = async (tagId) => {
    try {
      const response = await fetch(`${Global.url}tag/obtenertag/${tagId}`, { method: "GET" });
      if (!response.ok) throw new Error('Error al obtener los detalles del tag');
      const data = await response.json();
      console.log("Datos de la API:", data); // Añadir para depuración
      return data; // Asegúrate de que esto refleja la estructura correcta de tus datos
    } catch (error) {
      console.error("Error fetching tag details:", error);
      return null;
    }
  };

  useEffect(() => {
    const tenant_id = "6528fc86154f92783b692c9a";
    const api_key = "23310ceb-e09d-4427-969c-79e157f999b1";
    const host = "wss://mqtt.cloud.pozyxlabs.com:443";
    const topic = `${tenant_id}/tags`;
    const options = { username: tenant_id, password: api_key };

    const client = mqtt.connect(host, options);

    client.on('connect', () => {
      console.log('Conectado al broker MQTT');
      client.subscribe(topic, (err) => {
        if (!err) console.log(`Suscrito al tema: ${topic}`);
      });
    });

    client.on('message', async (topic, payload) => {
      const data = JSON.parse(payload.toString());
      if (data[0]?.data && data[0].data.coordinates) {
        const tagId = data[0].tagId;
        const tagDetails = await fetchTagDetails(tagId);
        
        // Verifica si 'tags' existe y tiene al menos un elemento
        const hasTags = tagDetails.tags && tagDetails.tags.length > 0;
        const iconUrl = hasTags ? tagDetails.tags[0].imagenUrl : 'default.png'; // Proporciona un valor predeterminado
        const nombre = hasTags ? tagDetails.tags[0].nombre : 'Desconocido'; // Proporciona un valor predeterminado
    
        setTagsData(prevTagsData => ({
          ...prevTagsData,
          [tagId]: {
            x: data[0].data.coordinates.x,
            y: data[0].data.coordinates.y,
            tagId,
            iconUrl, // Usando el valor condicional basado en la verificación
            nombre, // Usando el valor condicional basado en la verificación
          }
        }));
      }
    });

    return () => {
      client.end();
    };
  }, []);

  return (
    <div className='marco-mapa'>
      <h2>Visualizacion en tiempo real</h2>
      <Mapa2 className="mapa2" tagsData={tagsData} />
    </div>
  );
};

export default Plano;
