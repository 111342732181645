import React, { useState, useEffect } from 'react';
import { Global } from '../../../helpers/Global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export const ListaPorTag = () => {
    const [tag, setTag] = useState('');
    const [cargando, setCargando] = useState(false);
    const [orden, setOrden] = useState({ column: null, direction: 'asc' });
    const [lecturas, setLecturas] = useState([]);
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 100; // Número de elementos por página
    const [nombresTag, setNombresTag] = useState({});
    const [optionTag, setOptionTag] = useState([]);

    useEffect(() => {
        obtenerTagId();        
        const fetchData = async () => {
            const { objNombres, clavesOrdenadas } = await obtenerTagNombre();
            setNombresTag(objNombres);
        };

        fetchData();
    }, []); // Este efecto se ejecuta solo una vez al montar el componente

    const obtenerTagId = async () => {
        try {
            const url = `${Global.url}tag/listado`;
            const response = await fetch(url, {
                method: "GET",
            });
    
            if (!response.ok) {
                throw new Error('La respuesta de la API no es ok');
            }
    
            const datos = await response.json();
    
            if (datos.status === "success") {
                const tagsOrdenados = datos.tags.sort((a, b) => a.nombre.localeCompare(b.nombre));
                const opciones = tagsOrdenados.map(tag => ({ tagId: tag.tagId, nombre: tag.nombre }));
                setOptionTag(opciones);
            }
        } catch (error) {
            console.error('Error fetching tag IDs:', error);
        }
    }
    
    const conseguirLecturasPorTag = async (page = 1) => {
        setCargando(true);
        setError('');

        const url = `${Global.url}lectura/listadoTag/${tag}?page=${page}&limit=${itemsPerPage}`;
        try {
            let peticion = await fetch(url, {
                method: "GET",
            });
            const datos = await peticion.json();

            if (datos.status === "success") {
              const lecturasConNombres = datos.lecturas.map(lectura => {
                const tagNombre = nombresTag[lectura.tagId] || lectura.tagId;
                    
                    // Ajustar la lógica para 'zones'
                const zona = Array.isArray(lectura.data.zones) ?
                  (lectura.data.zones.length === 2 ? lectura.data.zones[1] : lectura.data.zones[0]) :
                      '';
                    
                return {
                    ...lectura,
                    tagId: tagNombre,
                    zona: zona // Almacenar la zona ajustada aquí
                    };
                });
                setLecturas(lecturasConNombres);
                setTotalPages(datos.totalPages);
                setCurrentPage(datos.page);
            } else {
                setLecturas([]);
                setError(datos.mensaje); // Mostrar mensaje de error desde el backend
            }
        } catch (error) {
            setLecturas([]);
            setError('Error al conectar con el servidor');
        } finally {
            setCargando(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (tag) {
            conseguirLecturasPorTag();
        } else {
            setError('Por favor, introduce un tag válido.');
        }
    };

    const obtenerTagNombre = async () => {
        try {
            const url = Global.url + "tag/listado";
            const response = await fetch(url, {
                method: "GET",
            });
    
            if (!response.ok) {
                throw new Error('La respuesta de la API no es ok');
            }
    
            const datos = await response.json();
    
            if (datos.status === "success") {
                const objNombres = datos.tags.reduce((acc, tag) => {
                    acc[tag.tagId] = tag.nombre;
                    return acc;
                }, {});
                const clavesOrdenadas = Object.keys(objNombres).sort((a, b) => objNombres[a].localeCompare(objNombres[b]));
                return { objNombres, clavesOrdenadas };
            }
    
            return { objNombres: {}, clavesOrdenadas: [] };
        } catch (error) {
            console.error('Error fetching tag names:', error);
            return { objNombres: {}, clavesOrdenadas: [] };
        }
    };
    

    const ordenarLecturas = (columna) => {
        const nuevaDireccion = orden.column === columna && orden.direction === 'asc' ? 'desc' : 'asc';
        setOrden({ column: columna, direction: nuevaDireccion });
        setLecturas(prevLecturas => {
            return [...prevLecturas].sort((a, b) => {
                let aValue = a[columna] || ''; 
                let bValue = b[columna] || '';

                if (aValue < bValue) {
                    return nuevaDireccion === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return nuevaDireccion === 'asc' ? 1 : -1;
                }
                return 0;
            });
        });
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            conseguirLecturasPorTag(newPage);
        }
    };

    return (
        <div>
            <form className='form-tag registro-fecha' onSubmit={handleSubmit}>
                <label htmlFor="tag">Seleccionar un tag:</label><br />
                <select
                    value={tag}
                    onChange={(e) => setTag(e.target.value)}
                >
                    <option selected>Seleccionar</option>
                    {optionTag.map((tagObj) => (
                        <option key={tagObj.tagId} value={tagObj.tagId}>
                            {tagObj.nombre} ({tagObj.tagId}) 
                        </option>
                    ))}
                </select>
                <button type="submit">Buscar</button>
            </form>
            {cargando && <p>Cargando...</p>}
            {error && <p>{error}</p>}
            {!cargando && lecturas.length > 0 && (
                <>
                    <h2 className='centrar-titulo'>{nombresTag[tag]}: {tag}</h2>
                    <table className='table-listado tam'>
                        <thead>
                            <tr className='tr-header-listado'>
                                <th className='th-header-listado' rowSpan="2" onClick={() => ordenarLecturas('zona')}>Zona</th>
                                <th className='th-header-listado' rowSpan="2" onClick={() => ordenarLecturas('date')}>Fecha registro</th>
                                <th className='th-header-listado block' colSpan="2">Coordenadas</th>
                            </tr>
                            <tr className='tr-header-listado'>
                                <th className='th-header-listado block'>Eje X</th>
                                <th className='th-header-listado block'>Eje Y</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lecturas.map(lectura => (
                                <tr className='tr-body-listado' key={lectura._id}>
                                    <td>{lectura.zona}</td>
                                    <td>{new Date(lectura.date).toLocaleString()}</td>
                                    <td>{lectura.data.coordinates.x}</td>
                                    <td>{lectura.data.coordinates.y}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
            {!cargando && !error && lecturas.length === 0 && <p>No hay lecturas para ese tag</p>}
            {!cargando && lecturas.length > 100 && (
                <div className='paginador'>
                    <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                        <FontAwesomeIcon icon={faChevronLeft} />
                    </button>
                    <span>Pag {currentPage} de {totalPages}</span>
                    <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                        <FontAwesomeIcon icon={faChevronRight} />
                    </button>
                </div>
            )}
        </div>
    );
};
