import './App.css';
import { Rutas } from './routing/Rutas';

function App() {
  return (
    <div className='layout'>
        <Rutas />
    </div>
  );
}

export default App;
