import React from "react";
import { Routes, Route, BrowserRouter, Navigate, Link } from "react-router-dom";
import { Inicio } from "../components/pages/Inicio";
import { Footer } from "../components/layout/Footer";
import { Listado } from "../components/pages/registros/Listado"
import PorFecha from "../components/pages/PorFecha";
import FlujoMapa from "../components/pages/mapa/FlujoMapa";
import Plano from "../components/pages/mapa/Plano";
import { PublicLayout } from "../components/layout/public/PublicLayout";
import { Login } from "../components/user/Login";
import { Register } from "../components/user/Register";
import { PrivateLayout } from "../components/layout/private/PrivateLayout";
import { AuthProvider } from "../context/AuthProvider";
import { LogOut } from "../components/user/logout";
import ListarTrabajos from "../components/pages/trabajos/ListaTrabajos";
import { ListaPorTag } from "../components/pages/registros/ListaPorTag";
import BuscarPorMatricula from "../components/pages/trabajos/BusquedaPorMatricula";
import AgregarTag from "../components/pages/etiquetas/AgregarTag";
import ListaDeTags from "../components/pages/etiquetas/ListaDeTags";
import ListaPorMatricula from "../components/pages/trabajos/ListaPorMatricula";
import CreaTarea from "../components/pages/trabajos/CreaTarea";
// import { ActualizarTags } from "../components/pages/etiquetas/ActualizarTags";
import { Actualizartag2 } from "../components/pages/etiquetas/Actualizartag2";
import ListaPorZona from "../components/pages/registros/ListaPorZona";
import { Ajustes } from "../components/pages/Ajustes";




export const Rutas = () => {
    return (
        <BrowserRouter>
            <AuthProvider>

                <Routes>
                    <Route path="/" element={<PublicLayout />}>
                        <Route index element={<Login />} />
                        <Route path="login" element={<Login />} />
                        <Route path="registro" element={<Register />} />
                    </Route>

                    <Route path="/app" element={<PrivateLayout />}>
                        <Route path="inicio" element={<Inicio />} />
                        <Route path="listado" element={<Listado />} />
                        <Route path="plano" element={<Plano/>} />
                        <Route path="porfecha" element={<PorFecha />} />
                        <Route path="creatarea" element={<CreaTarea />} />
                        <Route path="listatrabajos" element={<ListarTrabajos />} />
                        <Route path="listaportag" element={<ListaPorTag />} />
                        <Route path="listapormatricula" element={<ListaPorMatricula />} />
                        <Route path="agregartag" element={<AgregarTag />} />
                        <Route path="listatags" element={<ListaDeTags />} />
                        <Route path="listaporzona" element={<ListaPorZona />} />
                        <Route path="ajustes" element={<Ajustes />} />

                        <Route path="actualizartag/:tagId/:tagNombre/:tagIMG" element={<Actualizartag2/>} />

                        <Route path="logout" element={<LogOut />} />
                    </Route>

                    <Route path="*" element={
                        <>
                            <div><h1>Error 404</h1>
                                <Link to="/">Volver al Inicio</Link>
                            </div>
                        </>
                    } />

                </Routes>


                <Footer />

            </AuthProvider>
        </BrowserRouter >

    );
}
