import React, { useEffect, useState } from 'react';
import mqtt from 'mqtt';
import Mapa from './Mapa';

const FlujoMapa = () => {
  const [tagData, setTagData] = useState({ x: null, y: null, tagId: null });

  useEffect(() => {
    const tenant_id = "6528fc86154f92783b692c9a";
    const api_key = "23310ceb-e09d-4427-969c-79e157f999b1";
    const host = "wss://mqtt.cloud.pozyxlabs.com:443";
    const topic = `${tenant_id}/tags`;
    const options = {
      username: tenant_id,
      password: api_key,  
    };

    const client = mqtt.connect(host, options);

    client.on('connect', () => {
      console.log('Conectado al broker MQTT');
      client.subscribe(topic, (err) => {
        if (!err) {
          console.log(`Suscrito al tema: ${topic}`);
        }
      });
    });

    client.on('message', (topic, payload) => {
      const data = JSON.parse(payload.toString());
      if (data[0]?.data && data[0].data.coordinates) {
        const x = data[0].data.coordinates.x;
        const y = data[0].data.coordinates.y;
        const tagId = data[0].tagId;
        if (isFinite(x) && isFinite(y) && tagId) {
          setTagData({ x, y, tagId });
        }
      }
    });

    return () => client.end();
  }, []);

  useEffect(() => {
    console.log("Nuevos datos de Tag:", tagData);
  }, [tagData]);

  return (
    <div>
      <h1>Trama de datos recibida:</h1>
      <Mapa tagId={tagData.tagId} xCoord={tagData.x} yCoord={tagData.y} />
    </div>
  );
};

export default FlujoMapa;
