import React, { useState, useEffect } from 'react';
import { Global } from '../../../helpers/Global';

const CreaTarea = () => {
    const [identifier, setIdentifier] = useState('');
    const [zone, setZone] = useState('');
    const [zones, setZones] = useState([]);


    const [periods, setPeriods] = useState(() => {
        const savedPeriods = localStorage.getItem('periods');
        if (savedPeriods) {
            return JSON.parse(savedPeriods).map(period => ({
                ...period,
                periodStart: new Date(period.periodStart),
                periodEnd: period.periodEnd ? new Date(period.periodEnd) : null
            }));
        }
        return [];
    });

    useEffect(() => {
        localStorage.setItem('periods', JSON.stringify(periods));
    }, [periods]);

    useEffect(() => {
        const fetchZones = async () => {
            try {
                const response = await fetch(Global.url + 'zona/obtener-zonas', { method: 'GET' });
                if (!response.ok) {
                    throw new Error('Respuesta de la red no fue ok al intentar recuperar las zonas.');
                }
                const data = await response.json();
                setZones(data.zonas);
                console.log(data.zonas);
            } catch (error) {
                console.error('Error al recuperar las zonas:', error);
            }
        };

        fetchZones();
    }, []);

    const handleIdentifierChange = (e) => {
        if (!e.target.value) {
            alert("Ingrese la matrícul");
            return;
        }
        setIdentifier(e.target.value);
    };

    const handleZoneChange = (e) => {
        setZone(e.target.value);
    };

    const handleStartPeriod = (e) => {
        e.preventDefault();
        const now = new Date();
        const newPeriod = {
            id: Math.random(),
            identifier,
            zone,
            periodStart: now,
            periodEnd: null
        };
        setPeriods([...periods, newPeriod]);

    };

    const handleEndPeriod = (e, periodId) => {
        e.preventDefault();
        const updatedPeriods = periods.map(period => {
            if (period.id === periodId) {
                return { ...period, periodEnd: new Date() };
            }
            return period;
        });
        setPeriods(updatedPeriods);
    };

    const handleDeletePeriod = (e, periodId) => {
        e.preventDefault();
        setPeriods(periods.filter(period => period.id !== periodId));
    };

    const submitPeriod = async (e, periodId) => {
        e.preventDefault();
        const periodIndex = periods.findIndex(p => p.id === periodId);
        if (periodIndex === -1 || !periods[periodIndex].periodEnd) {
            console.error('El periodo no ha finalizado o no fue encontrado.');
            return;
        }

        const period = periods[periodIndex];

        console.log('Enviando al backend:', period);

        try {
            const response = await fetch(Global.url + 'matricula/inserta', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    identificador: period.identifier,
                    startTime: period.periodStart.getTime(),
                    endTime: period.periodEnd.getTime(),
                    zona: period.zone,
                }),
            });

            if (!response.ok) {
                throw new Error('Respuesta del servidor no es OK');
            }

            const responseData = await response.json();
            console.log('Respuesta del servidor:', responseData);

            const updatedPeriods = periods.filter((_, index) => index !== periodIndex);
            setPeriods(updatedPeriods);

            localStorage.setItem('periods', JSON.stringify(updatedPeriods));

            alert('Periodo enviado con éxito');
        } catch (error) {
            console.error('Error al enviar datos al servidor:', error);
            alert('Error al enviar el periodo');
        }
    };

    return (
        <div className='crea-content'>
            <form onSubmit={(e) => e.preventDefault()}>
                <div className='crea-form' >
                    <label htmlFor="crearMatricula"> Ingrese la matrícula del coche:</label>
                    <input
                        type="text"
                        value={identifier}
                        onChange={handleIdentifierChange}
                        placeholder=""
                    />
                    <select value={zone} onChange={handleZoneChange}>
                        <option value="">Seleccione una Zona</option>
                        {zones.map(z => (
                            <option key={z._id} value={z.nombre}>{z.nombre}</option>
                        ))}
                    </select>
                    <button type="button" onClick={handleStartPeriod}>Iniciar Período</button>
                </div>

                {periods.length > 0 && (
                    <table className='table-listado'> 
                    <thead>
                        <tr className='tr-header-listado'>
                            <th className='th-header-listado'>Identificador</th>
                            <th className='th-header-listado'>Zona</th>
                            <th className='th-header-listado'>Inicio</th>
                            <th className='th-header-listado'>Fin</th>
                            <th className='th-header-listado'>Acciones</th>
                        </tr>
                    </thead>


                    <tbody>
                        {periods.map((period) => (
                            <tr className='tr-body-listado' key={period.id}>
                                <td>{period.identifier}</td>
                                <td>{period.zone}</td>
                                <td>{period.periodStart.toLocaleString()}</td>
                                <td>{period.periodEnd ? period.periodEnd.toLocaleString() : 'En progreso'}</td>
                                <td>
                                    {!period.periodEnd && (
                                        <button onClick={(e) => handleEndPeriod(e, period.id)}>Finalizar Período</button>
                                    )}
                                    {period.periodEnd && (
                                        <button onClick={(e) => submitPeriod(e, period.id)}>Enviar Período</button>
                                    )}
                                    <button onClick={(e) => handleDeletePeriod(e, period.id)}>Eliminar</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                )}
                   
                 
            </form>
        </div>
    );
};

export default CreaTarea;