import React, { useState, useContext, createContext } from 'react';
import { Global } from '../../helpers/Global';
import AuthContext from '../../context/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

export const Ajustes = () => {
  const { changePassword  } = useContext(AuthContext);
  const [currentPassword, setCurrentPassword] = useState(false);
  const [newPassword, setNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [mensajeError, setMensajeError] = useState('');

  const actualizarPassword = async (currentPassword, newPassword) => {

    const success = await changePassword(currentPassword, newPassword);
    if (success) {
      alert("Contraseña cambiada correctamente.");
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } else {
      setMensajeError("Contraseña incorrecta");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const target = e.target;
  
    const contraseñaActual = target.elements.current.value;
    const contraseñaNueva = target.elements.new.value;
    const contraseñaConfirmar = target.elements.confirm.value;
  
    if (contraseñaNueva !== contraseñaConfirmar) {
      setMensajeError('Las contraseñas no coinciden');
      setTimeout(() => setMensajeError(''), 3000);
      return;
    }

    actualizarPassword(contraseñaActual, contraseñaNueva);

  }

  return (
    <div className='content-ajustes'>
      <div className='perfil'>
        <h2>{AuthContext.nick}</h2>
        <div className='contraseña'>
          <h4>Cambiar contraseña</h4>
          <form className='formulario-contraseña' onSubmit={handleSubmit}>
            <div className="form-contraseña label">
              <label>Contraseña actual</label><br />
              <label>Nueva contraseña</label><br />
              <label>Confirmar nueva contraseña</label><br /><br />
            </div>
            <div className="form-contraseña input">
              <input type={currentPassword ? 'text' : 'password'} name='current' id="current-password" />
              <i onClick={() => setCurrentPassword(!currentPassword)}>
                <FontAwesomeIcon icon={currentPassword ? faEyeSlash : faEye} />
              </i><br />

              <input type={newPassword ? 'text' : 'password'} name='new' id="new-password" />
              <i onClick={() => setNewPassword(!newPassword)}>
                <FontAwesomeIcon icon={newPassword ? faEyeSlash : faEye} />
              </i>
              <br />

              <input type={confirmPassword ? 'text' : 'password'} name='confirm' id="confirm-password" />
              <i onClick={() => setConfirmPassword(!confirmPassword)}>
                <FontAwesomeIcon icon={confirmPassword ? faEyeSlash : faEye} />
              </i>
              <p className='rojo'>{mensajeError}</p>
            </div>
            <div className="input-guardar">
              <input type="submit" value="Guardar" />         
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}


