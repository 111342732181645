import React, { useState, useEffect } from 'react';
import { Global } from '../../../helpers/Global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

const ListaPorZona = () => {
  const [zona, setZona] = useState('');
  const [orden, setOrden] = useState({ column: null, direction: 'asc' });
  const [lecturas, setLecturas] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [nombresTag, setNombresTag] = useState({});
  const [optionZona, setOptionZona] = useState([])

  useEffect(() => {
    obtenerTags();
    obtenerZonas();
  }, []);

  const obtenerZonas = async () => {
    try {
      const url = `${Global.url}zona/obtener-zonas`;
      const response = await fetch(url);
      const data = await response.json();
      setOptionZona(data.zonas.map(zona => ({ value: zona.nombre, label: zona.nombre })));
    } catch (error) {
      console.error("Error fetching zonas:", error);
    }
  };

  const obtenerTags = async () => {
    try {
      const url = `${Global.url}tag/listado`;
      const response = await fetch(url);
      const data = await response.json();
      const tagsOrdenados = data.tags.sort((a, b) => a.nombre.localeCompare(b.nombre));
      const tagNames = tagsOrdenados.reduce((acc, tag) => {
        acc[tag.tagId] = tag.nombre;
        return acc;
      }, {});
      setNombresTag(tagNames);
    } catch (error) {
      console.error("Error fetching tags:", error);
    }
  };

  const handleSubmit = (e) =>{
    e.preventDefault();
    if(zona){
        conseguirLecturasPorZona()
    }else{
        alert('Selecciones una zona');
    }
  }

  const conseguirLecturasPorZona = async () => {
    setCargando(true);
    try {
      const url = `${Global.url}lectura/listadozona/${zona}?page=${currentPage}&limit=100`;
      const response = await fetch(url);
      const data = await response.json();
      if (data.status === "success") {
        setLecturas(data.lecturas.map(lectura => ({
          ...lectura,
          tagNombre: nombresTag[lectura.tagId]
        })));
        setTotalPages(data.totalPages);
      } else {
        setError(data.mensaje);
      }
    } catch (error) {
      setError('Error al conectar con el servidor');
    } finally {
      setCargando(false);
    }
  }

  const ordenarLecturas = (columna) => {
    const nuevaDireccion = orden.column === columna && orden.direction === 'asc' ? 'desc' : 'asc';
    setOrden({ column: columna, direction: nuevaDireccion });
    setLecturas(prevLecturas => {
        return [...prevLecturas].sort((a, b) => {
            let aValue = a[columna] || ''; 
            let bValue = b[columna] || '';

            if (aValue < bValue) {
                return nuevaDireccion === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return nuevaDireccion === 'asc' ? 1 : -1;
            }
            return 0;
        });
      });
    };


  return (
    <div>
      <form className='form-zona registro-fecha' onSubmit={handleSubmit}>
        <label>Seleccionar una zona:</label>
        <select 
            value={zona}
             onChange={e => setZona(e.target.value)}
        >
          {optionZona.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <button type="submit">Buscar</button>
      </form>
      {cargando && <p>Cargando...</p>}
      {!cargando && lecturas && lecturas.length > 0 && (
        <>
        <h2 className='centrar-titulo'>{zona}</h2>
          <table className='table-listado '> 
          <thead>
            <tr className='tr-header-listado'>
              <th className='th-header-listado' onClick={() => ordenarLecturas('tagId')}>Nombre tag</th>
              <th className='th-header-listado' onClick={() => ordenarLecturas('date')}>Fecha registro</th>
              <th className='th-header-listado'>Eje X</th>
              <th className='th-header-listado'>Eje Y</th>
            </tr>
          </thead>
          <tbody>
            {lecturas.map((lectura, i) => (
              <tr className='tr-body-listado' key={i}>
                <td>{lectura.tagNombre || 'Desconocido'}</td>
                <td>{new Date(lectura.date).toLocaleString()}</td>
                <td>{lectura.data.coordinates.x}</td>
                <td>{lectura.data.coordinates.y}</td>
              </tr>
            ))}
          </tbody>
        </table>
      
        </>
      )}

      {lecturas && lecturas.length > 0 && (
         <div className='paginador'>
            <button disabled={currentPage === 1} onClick={() => setCurrentPage(prev => prev - 1)}>
            <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <span>Página {currentPage} de {totalPages}</span>
            <button disabled={currentPage === totalPages} onClick={() => setCurrentPage(prev => prev + 1)}>
            <FontAwesomeIcon icon={faChevronRight} />
            </button>
        </div>
      )}
     </div>
  );
};

export default ListaPorZona;
