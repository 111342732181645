import React, { useEffect, useState } from 'react';
import mqtt from 'mqtt';

const Mosquito = () => {
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    const tenant_id = "6528fc86154f92783b692c9a"
    const api_key = "23310ceb-e09d-4427-969c-79e157f999b1"
    
    const host = "wss://mqtt.cloud.pozyxlabs.com:443";
    const topic = tenant_id + "/tags";
    const username = tenant_id;
    const password = api_key;

    const options = {
      username: username,
      password: password,
    };

    const client = mqtt.connect(host, options);

    client.on('connect', () => {
      console.log('Conectado al broker MQTT');
      client.subscribe(topic, (err) => {
        if (!err) {
          console.log(`Suscrito al tema: ${topic}`);
        }
      });
    });

    client.on('message', (topic, payload) => {
      const data = payload.toString();
      console.log(`Nueva trama de datos: ${data}`);

      // Intentar analizar la cadena JSON
      try {
        const parsedData = JSON.parse(data);
        setJsonData(parsedData);
      } catch (error) {
        console.error('Error al analizar la cadena JSON:', error);
      }
    });

    return () => {
      client.end();
    };
  }, []);

  return (
    <div>
        <h1>Trama de datos recibida:</h1>
      {jsonData ? (
        <pre>{JSON.stringify(jsonData, null, 2)}</pre>
      ) : (
        <p>Esperando datos...</p>
      )}
    </div>
  );
};

export default Mosquito;
