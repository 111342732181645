import React from 'react';
import { NavLink } from 'react-router-dom'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket,faGear } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../../../hooks/useAuth';
import 'bootstrap/dist/css/bootstrap.min.css'

export const NavigationBar = () => {
    const { auth } = useAuth();

    return (
        <Navbar expand="lg" className='navi fixed-top'>
            
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto  menu">
                    <Nav.Link className='item-menu item-nav' as={NavLink} to="inicio">Inicio</Nav.Link>
                    <Nav.Link className='item-menu item-nav' as={NavLink} to="plano">Plano</Nav.Link>
                    <NavDropdown className='item-menu sub-menu nav-dropdown' title="Trabajos" id="basic-nav-dropdown">
                        <NavDropdown.Item as={NavLink} to="creatarea">Crear</NavDropdown.Item>
                        <NavDropdown.Item as={NavLink} to="listatrabajos">Histórico</NavDropdown.Item>
                         { /* <NavDropdown.Divider /> Para crear divisores en el menu */}
                        <NavDropdown.Item as={NavLink} to="listapormatricula">Por matrícula</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown className='item-menu sub-menu nav-dropdown' title="Registros" id="basic-nav-dropdown" >
                        <NavDropdown.Item as={NavLink} to="listado">Todos</NavDropdown.Item>
                        <NavDropdown.Item as={NavLink} to="porfecha">Por fecha</NavDropdown.Item>
                        <NavDropdown.Item as={NavLink} to="listaportag">Por etiqueta</NavDropdown.Item>
                        <NavDropdown.Item as={NavLink} to="listaporzona">Por zona</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown className='item-menu sub-menu nav-dropdown' title="Etiquetas" id="basic-nav-dropdown">
                        <NavDropdown.Item as={NavLink} to="agregartag">Crear</NavDropdown.Item>
                        <NavDropdown.Item as={NavLink} to="listatags">Listado</NavDropdown.Item>
                        <NavDropdown.Item as={NavLink} to="porfecha">Por etiqueta</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item as={NavLink} to="/otraOpcion">Otra Opción</NavDropdown.Item>
                    </NavDropdown>

                    <Nav.Link className='item-menu item-nav' href="#">{auth.nick}</Nav.Link>
                    <Nav.Link className='item-menu item-nav' as={NavLink} to="ajustes"><FontAwesomeIcon icon={faGear} /> Ajustes</Nav.Link>
                    <Nav.Link className='item-menu item-nav' as={NavLink} to="logout"><FontAwesomeIcon icon={faArrowRightFromBracket} /> Log out</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};
