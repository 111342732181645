import React, { useState } from 'react';
import { Global } from '../../../helpers/Global';

const BuscarPorMatricula = () => {
    const [periodos, setPeriodos] = useState([]);
    const [matricula, setMatricula] = useState('');
    const [error, setError] = useState('');
    const [orden, setOrden] = useState({ column: null, direction: 'asc' });

    const ordenarPeriodos = (columna) => {
        if (orden.column === columna) {
            setOrden({ ...orden, direction: orden.direction === 'asc' ? 'desc' : 'asc' });
        } else {
            setOrden({ column: columna, direction: 'asc' });
        }
    };

    const getSortedData = () => {
        return [...periodos].sort((a, b) => {
            if (a[orden.column] < b[orden.column]) {
                return orden.direction === 'asc' ? -1 : 1;
            }
            if (a[orden.column] > b[orden.column]) {
                return orden.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
    };

    const sortedPeriodos = getSortedData();

    const handleSubmit = async (e) => {
        e.preventDefault(); // Evita el comportamiento por defecto del formulario
        setError(''); // Limpia el mensaje de error previo antes de iniciar una nueva búsqueda
        if (matricula) {
            try {
                const response = await fetch(Global.url + 'matricula/pormatricula/'+ matricula);
                if (!response.ok) throw new Error('No se pudo obtener una respuesta satisfactoria de la API.');

                const data = await response.json();
                if (data.periodos && data.periodos.length > 0) {
                    setPeriodos(data.periodos);
                } else {
                    // Limpia los resultados anteriores si la nueva búsqueda no tiene resultados
                    setPeriodos([]);
                    setError('No se encontraron periodos para la matrícula proporcionada');
                }
            } catch (error) {
                // Asegúrate de limpiar los resultados anteriores si ocurre un error
                setPeriodos([]);
                setError('No se pudieron cargar los periodos: ' + error.message);
            }
        } else {
            // Si no se proporciona una matrícula, limpia los resultados y establece un mensaje de error
            setPeriodos([]);
            setError('Por favor, introduce una matrícula para buscar.');
        }
    };

    return (
        <div>
            <h2>Buscar Periodos por Matrícula</h2>
            <form onSubmit={handleSubmit}>
                <label htmlFor="matricula">Matrícula:</label>
                <input
                    type="text"
                    id="matricula"
                    value={matricula}
                    onChange={(e) => setMatricula(e.target.value)}
                />
                <button type="submit">Buscar</button>
            </form>
            {error && <p>{error}</p>}
            <table>
                <thead>
                    <tr>
                        <th onClick={() => ordenarPeriodos('identificador')}>Identificador</th>
                        <th onClick={() => ordenarPeriodos('zona')}>Zona</th>
                        <th onClick={() => ordenarPeriodos('startTime')}>Inicio</th>
                        <th onClick={() => ordenarPeriodos('endTime')}>Fin</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedPeriodos.map(periodo => (
                        <tr key={periodo._id}>
                            <td>{periodo.identificador}</td>
                            <td>{periodo.zona}</td>
                            <td>{new Date(periodo.startTime).toLocaleString()}</td>
                            <td>{new Date(periodo.endTime).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BuscarPorMatricula;

