import React from 'react';
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser,faUsers } from '@fortawesome/free-solid-svg-icons';

export const Nav = () => {
    return (
        <nav className=''>
            <ul>
                <li><NavLink to="/login"><FontAwesomeIcon icon={faUser} /> Login</NavLink></li>
                <li><NavLink to="/registro"><FontAwesomeIcon icon={faUsers} /> Registro</NavLink></li>
            </ul>
        </nav>
    )
}
