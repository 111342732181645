import React, { useState, useEffect } from 'react';
import { Global } from '../helpers/Global';

const Matricula3 = () => {
  const [identifier, setIdentifier] = useState('');
  const [zone, setZone] = useState('');
  const [zones, setZones] = useState([]);
  const [periods, setPeriods] = useState([]);

  useEffect(() => {
    const fetchZones = async () => {
      try {
        const response = await fetch(Global.url + 'zona/obtener-zonas');
        if (!response.ok) {
          throw new Error('Error al obtener zonas');
        }
        const data = await response.json();
        setZones(data.zonas);
      } catch (error) {
        console.error('Error al cargar zonas:', error);
      }
    };

    fetchZones();
  }, []);

  const handleIdentifierChange = (e) => {
    setIdentifier(e.target.value);
  };

  const handleZoneChange = (e) => {
    setZone(e.target.value);
  };

  const handleStartPeriod = () => {
    const now = new Date();
    const newPeriod = {
      id: Math.random(), // Generamos un identificador simple para demostración
      identifier,
      zone,
      periodStart: now,
      periodEnd: null
    };
    setPeriods([...periods, newPeriod]);
  };

  const handleEndPeriod = (periodId) => {
    const updatedPeriods = periods.map(period => {
      if (period.id === periodId) {
        return { ...period, periodEnd: new Date() };
      }
      return period;
    });
    setPeriods(updatedPeriods);
  };

  const submitPeriod = async (periodId) => {
    const periodIndex = periods.findIndex(p => p.id === periodId);
    if (periodIndex === -1 || !periods[periodIndex].periodEnd) {
      console.error('El periodo no ha finalizado o no fue encontrado.');
      return; // Salir si el periodo no ha finalizado o no existe
    }

    const period = periods[periodIndex];

    console.log('Enviando al backend:', period);

    try {
      const response = await fetch(Global.url + 'matricula/inserta', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          identificador: period.identifier,
          startTime: period.periodStart.getTime(),
          endTime: period.periodEnd.getTime(),
          zona: period.zone,
        }),
      });

      if (!response.ok) {
        throw new Error('Respuesta del servidor no es OK');
      }

      const responseData = await response.json();
      console.log('Respuesta del servidor:', responseData);

      // Eliminar el periodo enviado de la lista o marcarlo como enviado
      const updatedPeriods = [...periods];
      updatedPeriods.splice(periodIndex, 1); // Opción de eliminar el periodo
      setPeriods(updatedPeriods);

      alert('Periodo enviado con éxito'); // Feedback visual para el usuario
    } catch (error) {
      console.error('Error al enviar datos al servidor:', error);
      alert('Error al enviar el periodo'); // Feedback visual para el usuario
    }
  };


  return (
    <div>
      <input
        type="text"
        value={identifier}
        onChange={handleIdentifierChange}
        placeholder="Ingrese la matrícula del coche"
      />
      <select value={zone} onChange={handleZoneChange}>
        <option value="">Seleccione una Zona</option>
        {zones.map(z => (
          <option key={z._id} value={z.nombre}>{z.nombre}</option>
        ))}
      </select>
      <button onClick={handleStartPeriod}>Iniciar Período</button>

      <div>
        {periods.map((period) => (
          <div key={period.id}>
            <p>Periodo: {period.identifier} en {period.zone} - Inicio: {period.periodStart.toLocaleString()}</p>
            {!period.periodEnd ? (
              <button onClick={() => handleEndPeriod(period.id)}>Finalizar Período</button>
            ) : (
              <>
                <p>Finalizado: {period.periodEnd.toLocaleString()}</p>
                <button onClick={() => submitPeriod(period.id)}>Enviar Período</button>
              </>
            )}
          </div>
        ))}
      </div>

    </div>
  );
};

export default Matricula3;
