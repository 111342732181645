import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Global } from '../../../helpers/Global';

export const Modal = ({ isOpen, onClose, matricula, lecturas, periodo }) => {
  const [tagData, setTagData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [diasTotal, setDiasTotal] = useState('');

  useEffect(() => {
    if (isOpen && lecturas && periodo) {
      fetchAndProcessTags(lecturas);
      calculateTotalTime();
    }
  }, [isOpen, lecturas, periodo]);

  const calculateTotalTime = () => {
    const { days, hours, minutes, seconds } = getTimeDifference(periodo.startTime, periodo.endTime);
    const formattedTime = days > 0 
      ? `Días: ${days} - ${hours}:${minutes}:${seconds}`
      : `${hours}:${minutes}:${seconds}s`;
    setDiasTotal(formattedTime);
  };

  const getTimeDifference = (startTime, endTime) => {
    const ms = new Date(endTime).getTime() - new Date(startTime).getTime();
    const days = Math.floor(ms / (24 * 60 * 60 * 1000));
    const hours = Math.floor((ms % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    const minutes = Math.floor((ms % (60 * 60 * 1000)) / (60 * 1000));
    const seconds = Math.floor((ms % (60 * 1000)) / 1000);
    return { days, hours, minutes, seconds };
  };
  

  const calcularDistanciaTotal = (lecturas) => {
    let distanciaTotal = 0;
    lecturas.sort((a, b) => new Date(a.date) - new Date(b.date));
    console.log("Lecturas ordenadas:", lecturas);  // Diagnóstico: ver el orden de las lecturas
  
    for (let i = 1; i < lecturas.length; i++) {
      const prev = lecturas[i - 1];
      const curr = lecturas[i];
      console.log(prev)
      console.log(curr)
      console.log(prev.data.coordinates)
      console.log(curr.data.coordinates)
      if (prev.data.coordinates && curr.data.coordinates && prev.data.coordinates.x !== undefined && curr.data.coordinates.x !== undefined) {
        const dist = Math.sqrt(
          Math.pow((curr.data.coordinates.x - prev.data.coordinates.x), 2) +
          Math.pow((curr.data.coordinates.y - prev.data.coordinates.y), 2) +
          Math.pow((curr.data.coordinates.z - prev.data.coordinates.z), 2)
        );
        distanciaTotal += dist;
      } else {
        console.error("Datos de coordenadas faltantes o incorrectos en las lecturas", prev, curr);
      }
    }
    return distanciaTotal / 1000; // Convertir de milímetros a metros
  };
  
  const fetchAndProcessTags = async (lecturas) => {
    setLoading(true);
    setError('');
    try {
      const tagDetails = {};
      lecturas.forEach(lectura => {
        if (!tagDetails[lectura.tagId]) {
          tagDetails[lectura.tagId] = {
            lecturas: [],
            nombre: '',
            count: 0,
            distanciaTotal: 0
          };
        }
        tagDetails[lectura.tagId].lecturas.push(lectura);
        tagDetails[lectura.tagId].count++;
      });
  
      const tagData = await Promise.all(Object.keys(tagDetails).map(async tagId => {
        const nombreTag = await obtenerNombreTag(tagId);
        const distanciaTotal = calcularDistanciaTotal(tagDetails[tagId].lecturas);
        console.log(`Distancia total para ${nombreTag}: ${distanciaTotal} metros`);  // Diagnóstico: verificar las distancias calculadas
        return {
          name: nombreTag,
          count: (tagDetails[tagId].count / 30).toFixed(2),
          distanciaTotal: distanciaTotal.toFixed(2)
        };
      }));
  
      setTagData(tagData);
    } catch (error) {
      setError('No se pudieron cargar los nombres de los tags');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  

  const procesarLecturas = (lecturas) => {
    const tagCounts = {};
    lecturas.forEach(lectura => {
      const tagId = lectura.tagId;
      tagCounts[tagId] = (tagCounts[tagId] || 0) + 1;
    });
    Object.keys(tagCounts).forEach(tag => {
      tagCounts[tag] = (tagCounts[tag] / 30).toFixed(2); // Divide cada conteo por 30 y formatea a dos decimales
    });
    return tagCounts;
  };

  const obtenerNombreTag = async (idLectura) => {
    try {
      const url = `${Global.url}tag/obtenertag/${idLectura}`;
      console.log(url)
      const response = await fetch(url, { method: 'GET' });
      if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
      const data = await response.json();
      console.log(data.tags[0]);
      return data.status === 'success' ? data.tags[0].nombre : idLectura;
    } catch (error) {
      console.error("Error al obtener el nombre por ID", error);
      return idLectura;  // Fallback to ID
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h1>Matrícula: {matricula}</h1>
        <button onClick={onClose} className="closeButton">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {loading ? (
          <p>Cargando...</p>
        ) : error ? (
          <p>Error: {error}</p>
        ) : tagData.length > 0 ? (
          <table className="table-listado">
            <thead>
              <tr className="tr-header-listado">
                <th className="th-header-listado">Zona</th>
                <th className="th-header-listado">Inicio</th>
                <th className="th-header-listado">Fin</th>
                <th className='th-header-listado'>Periodo total</th>
                <th className="th-header-listado">Tag</th>
                <th className="th-header-listado">Distancia total</th>
              </tr>
            </thead>
            <tbody>
              {tagData.map((tag, index) => (
                <tr className="tr-body-listado" key={index}>
                  <td>{periodo.zona}</td>
                  <td>{new Date(periodo.startTime).toLocaleString()}</td>
                  <td>{new Date(periodo.endTime).toLocaleString()}</td>
                  <td>{diasTotal}</td>
                  <td>{`${tag.name}`} <strong>{tag.count}min</strong> </td>  
                  <td><strong>{tag.distanciaTotal}m</strong> </td>  
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No hay lecturas disponibles para esta matrícula.</p>
        )}
      </div>
    </div>
  );
};

export default Modal;