import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

export const LogOut = () => {

    const {setAuth} = useAuth();
    const navigate = useNavigate()

    useEffect(() => {

        // Vaciar el localStorage
        localStorage.clear();

        // Setear estaos globales a vacío
        setAuth({});
        
        // Redireccionar al login
        navigate("/login");

    });

    return (
        <h2>Cerrando sesión</h2>
    );
}
