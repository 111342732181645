import React, { useState, useEffect } from 'react';
import { Global } from '../helpers/Global';

const Matricula2 = () => {
  const [identifier, setIdentifier] = useState('');
  const [zone, setZone] = useState('');
  const [zones, setZones] = useState([]);
  const [periodStart, setPeriodStart] = useState(null);
  const [periodEnd, setPeriodEnd] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState(''); // Nuevo estado para el estado de envío

  useEffect(() => {
    const fetchZones = async () => {
      try {
        const response = await fetch(Global.url + 'zona/obtener-zonas');
        if (!response.ok) {
          throw new Error('Error al obtener zonas');
        }
        const data = await response.json();
        setZones(data.zonas);
      } catch (error) {
        console.error('Error al cargar zonas:', error);
      }
    };

    fetchZones();
  }, []);

  const handleIdentifierChange = (e) => {
    setIdentifier(e.target.value);
  };

  const handleZoneChange = (e) => {
    setZone(e.target.value);
    console.log(zone);
  };

  const handleStartPeriod = () => {
    const now = new Date();
    setPeriodStart(now);
    setPeriodEnd(null);
    console.log(now);
    console.log(identifier);
  };

  const handleEndPeriod = () => {
    const now = new Date();
    setPeriodEnd(now);
    console.log(now);
    console.log(identifier);
  };

  const submitPeriod = async () => {
    setSubmissionStatus('Enviando...'); // Actualiza el estado para indicar que está enviando
    console.log('Enviando al backend:', { identifier, periodStart, periodEnd, zone });

    try {
      const response = await fetch(Global.url + 'matricula/inserta', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          identificador: identifier,
          startTime: periodStart.getTime(),
          endTime: periodEnd.getTime(),
          zona: zone,
        }),
      });

      if (!response.ok) {
        throw new Error('Respuesta del servidor no es OK');
      }

      const responseData = await response.json();
      console.log('Respuesta del servidor:', responseData);
      setSubmissionStatus('Registro enviado con éxito!'); // Actualiza el estado para indicar éxito
    } catch (error) {
      console.error('Error al enviar datos al servidor:', error);
      setSubmissionStatus('Error al enviar el registro.'); // Actualiza el estado para indicar error
    }
  };

  return (
    <div>
      <input
        type="text"
        value={identifier}
        onChange={handleIdentifierChange}
        placeholder="Ingrese la matrícula del coche"
      />
      <select value={zone} onChange={handleZoneChange}>
        <option value="">Seleccione una Zona</option>
        {zones.map(z => (
          <option key={z._id} value={z.nombre}>{z.nombre}</option>
        ))}
      </select>
      <button onClick={handleStartPeriod}>Iniciar Período</button>
      <button onClick={handleEndPeriod} disabled={!periodStart}>Finalizar Período</button>
      <button onClick={submitPeriod} disabled={!periodEnd}>Enviar Período</button>

      {periodStart && <p>Periodo iniciado: {periodStart.toLocaleString()}</p>}
      {periodEnd && <p>Periodo finalizado: {periodEnd.toLocaleString()}</p>}
      {/* Muestra el mensaje de estado de envío */}
      {submissionStatus && <p>{submissionStatus}</p>}
    </div>
  );
};

export default Matricula2;
