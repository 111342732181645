import React, { useEffect, useState } from 'react';
import { Global } from '../../../helpers/Global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

function ListaDeTags() {
  const [tags, setTags] = useState([]);
  const [cargando, setCargando] = useState(true);
  const [error, setError] = useState(null);
  const [orden, setOrden] = useState('asc');
  const [columnaOrdenada, setColumnaOrdenada] = useState('');

  useEffect(() => {
    cargarTags();
  }, []);

  const cargarTags = async () => {
    try {
      const respuesta = await fetch(Global.url + 'tag/listado', {
        method: "GET"
      });
      if (!respuesta.ok) {
        throw new Error('Algo salió mal al cargar los tags');
      }
      const tags = await respuesta.json();
      setTags(tags.tags);
      setCargando(false);
    } catch (error) {
      setError(error.message);
      setCargando(false);
    }
  };

  // Función para ordenar los tags
  const ordenarTags = (columna) => {
    const nuevoOrden = orden === 'asc' ? 'desc' : 'asc';
    const tagsOrdenados = [...tags].sort((a, b) => {
      if (a[columna] < b[columna]) {
        return nuevoOrden === 'asc' ? -1 : 1;
      }
      if (a[columna] > b[columna]) {
        return nuevoOrden === 'asc' ? 1 : -1;
      }
      return 0;
    });

    setTags(tagsOrdenados);
    setOrden(nuevoOrden);
    setColumnaOrdenada(columna);
  };

  // const eliminarTag = async (tagId) => {
  //   if (window.confirm('¿Estás seguro de que deseas eliminar este tag?')) {
  //     try {
  //       const respuesta = await fetch(Global.url + 'tag/eliminar/' + tagId, {
  //         method: 'DELETE',
  //       });
  //       if (!respuesta.ok) throw new Error('Error al eliminar el tag');
  //       cargarTags(); // Recargar la lista después de eliminar
  //     } catch (error) {
  //       alert(`Error al eliminar el tag: ${error.message}`);
  //     }
  //   }
  // };
  const eliminarTag = (tagId) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este tag visualmente?')) {
      setTags(tags.filter(tag => tag.tagId !== tagId));
    }
  };

  if (cargando) return <div>Cargando tags...</div>;
  if (error) return <div>Error al cargar los tags: {error}</div>;

  return (
    <div className='form-tags-lista'>
      <h2 className='title-listado'>Lista de Tags</h2>
      <table className='table-listado'>
        <thead>
          <tr className='tr-header-listado'>
            <th className='th-header-listado' onClick={() => ordenarTags('tagId')}>Tag ID</th>
            <th className='th-header-listado' onClick={() => ordenarTags('nombre')}>Nombre</th>
            <th className='th-header-listado'>Imagen URL</th>
            <th className='th-header-listado'>Acciones</th> {/* Esta columna contiene los botones y no debe ser ordenable */}
            <th className='th-header-listado'>Actualizar</th>
          </tr>
        </thead>
        <tbody>
          {tags.map((tag) => (
            <tr className='tr-body-tags' key={tag.tagId}>
              <td>{tag.tagId}</td>
              <td>{tag.nombre}</td>
              <td><img src={tag.imagenUrl} alt={tag.nombre} style={{ width: 50, height: 50 }} /></td>
              <td>
                <button className='butt button-delete' onClick={() => eliminarTag(tag.tagId)}>
                  <FontAwesomeIcon className='ico-delete' icon={faTrash} />
                </button>
              </td>
              <td>
                <Link to={`/app/actualizartag/${tag.tagId}/${tag.nombre}/${tag.imagenUrl}`} >
                  <FontAwesomeIcon className='ico-modificar' icon={faPenToSquare} />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ListaDeTags;
