import logo from '../../../img/vect.svg';
import React from 'react'
import { NavigationBar } from './Nav';


export const Header = () => {
    return (
        <>
        <div className='header fixed-top'>
            <div className="logo">
                <img src={logo} alt='logo'/>
            </div>
            <h1> Proyecto Reyes</h1>
        </div>
        <NavigationBar />
        </>
    )
}
