import React, { useEffect, useRef } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import planoTaller from '../../../img/PlanoTaller2X.png' // Asegúrate de que esta ruta es correcta
import markerIconUrl from '../../../img/marker-icon.png'; // Ruta al icono del marcador
//import markerIconUrl from '../img/iconos/aspiradorAceite.svg'

const Mapa = ({ tagId, xCoord, yCoord }) => {
  const mapRef = useRef(null);
  const markersRef = useRef({});

  // Definición del icono personalizado
  const customIcon = L.icon({
    iconUrl: markerIconUrl,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });

  // Rangos del sistema de origen y de Leaflet correctamente definidos
  const origenXRange = { min: -10500, max: 14000 };
  const origenYRange = { min: -7000, max: 5000 };
  const leafletXRange = { min: 0, max: 2106 }; // Ajustado a la anchura de tu imagen
  const leafletYRange = { min: 0, max: 1024 }; // Ajustado a la altura de tu imagen

  useEffect(() => {
    if (!mapRef.current) {
      const crs = L.CRS.Simple;
      mapRef.current = L.map('map-container', { crs: crs, minZoom: -5 });

      // Ajusta los límites del mapa a las dimensiones de tu imagen
      const bounds = [[0, 0], [1024, 2106]];
      L.imageOverlay(planoTaller, bounds).addTo(mapRef.current);
      mapRef.current.fitBounds(bounds);
    }
  }, []);

  useEffect(() => {
    if (tagId && Number.isFinite(xCoord) && Number.isFinite(yCoord) && mapRef.current) {
      // Transformación lineal para el eje X
      const leafletX = ((xCoord - origenXRange.min) / (origenXRange.max - origenXRange.min)) * (leafletXRange.max - leafletXRange.min);
  
      // Transformación lineal para el eje Y, ajustada para el eje Y invertido
      const leafletY = (yCoord - origenYRange.min) / (origenYRange.max - origenYRange.min) * 1024;
  
      const latLng = L.latLng([leafletY, leafletX]);
      addOrUpdateMarker(tagId, latLng.lat, latLng.lng);
    }
  }, [tagId, xCoord, yCoord]);
  

  const addOrUpdateMarker = (tagId, lat, lng) => {
    if (markersRef.current[tagId]) {
      markersRef.current[tagId].setLatLng([lat, lng]);
    } else {
      const marker = L.marker([lat, lng], { icon: customIcon }).addTo(mapRef.current);
      marker.bindPopup(`Tag: ${tagId}`);
      markersRef.current[tagId] = marker;
    }
  };

  return <div id="map-container" style={{ width: '100%', height: '500px' }} />;
};

export default Mapa;

