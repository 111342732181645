import React, { useState, useEffect } from 'react';
import { Global } from '../../helpers/Global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'

const PorFecha = () => {
  const [lecturas, setLecturas] = useState([]);
  const [cargando, setCargando] = useState(false);
  const [orden, setOrden] = useState({ column: null, direction: 'asc' });
  const [startDate, setStartDate] = useState('2024-05-24');
  const [startTime, setStartTime] = useState('00:00');
  const [endDate, setEndDate] = useState('2024-05-24');
  const [endTime, setEndTime] = useState('00:00');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const itemsPerPage = 100; // Número de elementos por página
  
  const [errorFecha, setErrorFecha] = useState('');
  const [errorFechaFin, setErrorFechaFin] = useState('');
  const [nombresTag, setNombresTag] = useState({});

  useEffect(() => {
      const fetchData = async () => {
          const nombres = await obtenerTagNombre();
          setNombresTag(nombres);
      };

      fetchData();
  }, []); // Este efecto se ejecuta solo una vez al montar el componente

  const convertToUTC = (date, time) => {
      return new Date(`${date}T${time}:00Z`).toISOString();
  };

  const formatDateStr = (date) => {
      return new Date(date).toISOString().split('T')[0];  // Retorna 'YYYY-MM-DD'
  };

  const obtenerTagNombre = async () => {
      try {
          const url = Global.url + "tag/listado";
          const response = await fetch(url, {
              method: "GET",
          });

          if (!response.ok) {
              throw new Error('La respuesta de la API no es ok');
          }

          const datos = await response.json();

          if (datos.status === "success") {
              const objNombres = datos.tags.reduce((acc, tag) => {
                  acc[tag.tagId] = tag.nombre;
                  return acc;
              }, {});
              return objNombres;
          }

          return {};
      } catch (error) {
          console.error('Error fetching tag names:', error);
          return {};
      }
  };

  const handleSearch = async (e) => {
      e.preventDefault();
      const today = formatDateStr(new Date());

      if (startDate > today) {
          setErrorFecha("Inserte una fecha correcta");
          setTimeout(() => {
              setErrorFecha("")
          }, 2000);
          return;
      }

      if (endDate > today || endDate < startDate) {
          setErrorFechaFin("Inserte una fecha correcta");
          setTimeout(() => {
              setErrorFechaFin("")
          }, 2000);
          return;
      }

      setCurrentPage(1);
      await obtenerRegistrosFecha({ startDate, startTime, endDate, endTime }, 1);
  };

  const ordenarLecturas = (columna) => {
    console.log(columna);
    const nuevaDireccion = orden.column === columna && orden.direction === 'asc' ? 'desc' : 'asc';
    setOrden({ column: columna, direction: nuevaDireccion });
    setLecturas(prevLecturas => {
        return [...prevLecturas].sort((a, b) => {
            let aValue = a[columna];
            let bValue = b[columna];

            if (aValue < bValue) {
                return nuevaDireccion === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return nuevaDireccion === 'asc' ? 1 : -1;
            }
            return 0;
        });
    });
};


const obtenerRegistrosFecha = async (periodoBusqueda, page = 1) => {
    try {
        setCargando(true); // Empezar a cargar
        const { startDate, startTime, endDate, endTime } = periodoBusqueda;

        const response = await fetch(`${Global.url}lectura/listadoFecha?page=${page}&limit=${itemsPerPage}&startDate=${startDate}&startTime=${startTime}&endDate=${endDate}&endTime=${endTime}`, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error('La respuesta de la API no es ok');
        }

        const data = await response.json();

        if (data.status === "success") {
            const lecturasTransformadas = data.lecturas.map(lectura => {
                let zona = '';
                if (Array.isArray(lectura.data.zones)) {
                    if (lectura.data.zones.length === 1) {
                        zona = lectura.data.zones[0]; // Si hay un solo elemento, tomar ese
                    } else if (lectura.data.zones.length >= 2) {
                        zona = lectura.data.zones[1]; // Si hay dos o más, tomar el segundo
                    }
                }

                return {
                    ...lectura,
                    zona: zona // Asegurarse de que este campo se muestre correctamente en la tabla
                };
            });

            setLecturas(lecturasTransformadas);
            setTotalPages(data.totalPages);
        } else {
            setLecturas([]);
            setTotalPages(1);
        }

        setCargando(false); // Terminar de cargar
    } catch (error) {
        console.error('Error fetching data:', error);
        setCargando(false); // Terminar de cargar en caso de error
    }
};


  const handlePageChange = async (newPage) => {
      setCurrentPage(newPage);
      await obtenerRegistrosFecha({ startDate, startTime, endDate, endTime }, newPage);
  };

  return (
      <div>
          <form className='registro-fecha' onSubmit={handleSearch}>
              <div>
                  <label>Fecha de inicio:
                      <br /><input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                      <p className='error'>{errorFecha}</p>
                  </label>
                  <label>Hora de inicio
                      <br /><input type="time" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                  </label>
              </div>

              <div>
                  <label>Fecha de fin:
                      <br /><input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                      <p className='error'>{errorFechaFin}</p>
                  </label>
                  <label>Hora de fin
                      <br /><input type="time" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
                  </label>
              </div>

              <button className='btn-fecha' type="submit">Buscar</button>
          </form>

          {cargando ? <h3>Cargando...</h3> : (
              lecturas.length > 0 ? (
                  <>
                      <table className='table-listado'>
                          <thead>
                              <tr className='tr-header-listado'>
                                  <th className='th-header-listado' rowSpan="2" onClick={() => ordenarLecturas('tagId')}>Identificador</th>
                                  <th className='th-header-listado' rowSpan="2" onClick={() => ordenarLecturas('zona')}>Zona</th>
                                  <th className='th-header-listado' rowSpan="2" onClick={() => ordenarLecturas('date')}>Fecha registro</th>
                                  <th className='th-header-listado block' colSpan="2">Coordenadas</th>
                              </tr>
                              <tr className='tr-header-listado'>
                                  <th className='th-header-listado block'>Eje X</th>
                                  <th className='th-header-listado block'>Eje Y</th>
                              </tr>
                          </thead>
                          <tbody>
                           {lecturas.map(lectura => {
                               // Determina qué mostrar en 'Zona'
                               const zonaDisplay = Array.isArray(lectura.data.zones) ?
                                                   (lectura.data.zones.length >= 2 ? lectura.data.zones[1] : lectura.data.zones[0]) :
                                                   '';
                              return (
                                  <tr className='tr-body-listado' key={lectura._id}>
                                      <td>{nombresTag[lectura.tagId]}</td>
                                      <td>{zonaDisplay}</td> {/* Muestra la zona ajustada */}
                                      <td>{new Date(lectura.date).toLocaleString()}</td>
                                      <td>{lectura.data.coordinates.x}</td>
                                      <td>{lectura.data.coordinates.y}</td>
                                  </tr>
                              );
                          })}
                      </tbody>
                      </table>

                      <div className='paginador'>
                          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                              <FontAwesomeIcon icon={faChevronLeft} />
                          </button>
                          <span>Pag {currentPage} de {totalPages}</span>
                          <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                              <FontAwesomeIcon icon={faChevronRight} />
                          </button>
                      </div>
                  </>
              ) : (
                <p>Aún no hay lecturas por mostrar</p>
              )
          )}
      </div>
  );
};

export default PorFecha;