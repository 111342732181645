import React from 'react'
import { useForm } from '../../hooks/useForm'
import { Global } from "../../helpers/Global"
import { Link } from 'react-router-dom'

export const Register = () => {

  const { formulario, cambiado } = useForm()

  const saveUser = async (e) => {
    e.preventDefault();
  
    let newUser = formulario;
    const url = Global.url + 'user/registro';
    console.log("URL de la solicitud:", url);
    console.log("Datos del formulario antes de enviar:", newUser);
  
    try {
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(newUser),
        headers: {
          "Content-Type": "application/json",
        }
      });
  
      if (!response.ok) {
        const errorResponse = await response.json(); // Obtener más detalles del error
        console.error('Error en la solicitud:', errorResponse);
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
      console.log("Respuesta del servidor:", data);
    } catch (error) {
      console.error("Error en la solicitud:", error.message);
    }
  }
  

  return (
    <>
       <div className='layout_identificarse'>
        <header className='content__header'>
          <h1 className='content__title'>Crear cuenta</h1>
        </header>

        <div className='content__posts'>

          <form className='register-form' onSubmit={saveUser}>

            <div className='form-group'>
              <label htmlFor="name">Nombre:</label><br/>
              <input className='input-layout' type='text' name='name' onChange={cambiado} />
            </div>

            <div className='form-group'>
              <label htmlFor="surname">Apellidos:</label><br/>
              <input className='input-layout' type='text' name='surname' onChange={cambiado} />
            </div>

            <div className='form-group'>
              <label htmlFor="email">Correo electrónico:</label><br/>
              <input className='input-layout' type='email' name='email' onChange={cambiado} />
            </div>

            <div className='form-group'>
              <label htmlFor="name">Contraseña:</label><br/>
              <input className='input-layout' type='password' name='password' onChange={cambiado} />
            </div>
            <Link className='iniciar-sesion' to='/login'>Iniciar sesión</Link>
            <input type='submit' value="Registrate" className='btn btn-login' />

        </form>
      </div>
      </div>
    </>
  )
}
