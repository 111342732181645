import React from 'react'
import { Header } from './Header';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';

export const PrivateLayout = () => {

  const {auth} = useAuth();

  return (
    <>
        <Header />

        <section id="content" className="layout-content">
        {auth._id ?
            <Outlet />
          : 
          <Navigate to="/" />
          }
        </section>
    </>
  )
}