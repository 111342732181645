import React, { useState, useEffect } from 'react';
import { Global } from '../../../helpers/Global';
import { Modal } from '../trabajos/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const ListarTrabajos = () => {
    const [periodos, setPeriodos] = useState([]);
    const [error, setError] = useState('');
    const [orden, setOrden] = useState({ column: null, direction: 'asc' });
    const [modalOpen, setModalOpen] = useState({});
    const [lecturasPorPeriodo, setLecturasPorPeriodo] = useState({});
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [singleDay, setSingleDay] = useState(false);
    const [errorFecha, setErrorFecha] = useState('');
    const [errorFechaFin, setErrorFechaFin] = useState('');

    const fetchPeriodos = async () => {
         try {
            const response = await fetch(Global.url + 'matricula/obtener', { method: 'GET' });

            if (!response.ok) {
                throw new Error('La respuesta de la API no es ok');
            }

            const data = await response.json();
            setPeriodos(data.periodos);
            setError('');
        } catch (error) {
            setError('No se pudieron cargar los periodos: ' + error.message);
        }
    };

    useEffect(() => {
        fetchPeriodos();
    }, []);

    const formatDateStr = (date) => {
        return new Date(date).toISOString().split('T')[0];  // Retorna 'YYYY-MM-DD'
    };

    const formatTimeStr = (date) => {
        return new Date(date).toISOString().split('T')[1].substring(0, 8);  // Retorna 'HH:MM:SS'
    };

    const fetchAndFilterLecturas = async (periodo) => {
        const startDateStr = formatDateStr(periodo.startTime);
        const startTimeStr = formatTimeStr(periodo.startTime);
        const endDateStr = formatDateStr(periodo.endTime);
        const endTimeStr = formatTimeStr(periodo.endTime);

        const url = `${Global.url}lectura/listadofecha?startDate=${startDateStr}&startTime=${startTimeStr}&endDate=${endDateStr}&endTime=${endTimeStr}`;

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Error fetching data: Status ${response.status}`);
            }

            const data = await response.json();

            if (data.lecturas && data.lecturas.length > 0) {
                const lecturasFiltradas = data.lecturas.filter(lectura =>
                    lectura.data && lectura.data.zones && lectura.data.zones.includes(periodo.zona)
                );

                setLecturasPorPeriodo(prevState => ({
                    ...prevState,
                    [periodo._id]: lecturasFiltradas
                }));
            } else {
                console.log('No lecturas found or empty lecturas array.');
            }
        } catch (error) {
            console.error('Failed to fetch or process lecturas:', error);
        }
    };

    const filtradoPorFecha = async (startDate, endDate = null) => {
        try {
            const response = await fetch(Global.url + 'matricula/obtener', { method: 'GET' });

            if (!response.ok) {
                throw new Error('La respuesta de la API no es ok');
            }

            const data = await response.json();
            const fechasDia = data.periodos;
            const filteredPeriodos = fechasDia.filter(periodo => {
                const dia = new Date(periodo.startTime);
                const compararDia = formatDateStr(dia);

                if (endDate) {
                    return (compararDia >= startDate) && (compararDia <= endDate);
                } else {
                    return compararDia === startDate;
                }
            });
            console.log(filteredPeriodos)

            if(filteredPeriodos.length === 0){
                setError("No hay datos para mostrar");
                setPeriodos([])
                return;
            }   

            setPeriodos(filteredPeriodos);

        } catch (error) {
            setError('No se pudieron cargar los periodos: ' + error.message);
        }
    };

    const handleSearch = (e) => {
        e.preventDefault();
        const today = formatDateStr (new Date());

        if (!startDate) {
            alert("Inserte fecha de inicio");
            return;
        }else if (startDate > today){
            setErrorFecha("Inserte una fecha correcta")            
            setTimeout(() => {
                setErrorFecha("")
            }, 2000)

            return;            
        }

        if (singleDay) {
            filtradoPorFecha(startDate);
        } else {
            if (!endDate) {
                alert("Por favor, seleccione la fecha de fin");
                return;
            }else if(endDate >  today || endDate<startDate){
                setErrorFechaFin("Inserte una fecha correcta")            
                setTimeout(() => {
                    setErrorFechaFin("")
                }, 2000)

                return;
            }else{
                filtradoPorFecha(startDate, endDate);
            }   
        }
    };

    const ordenarPeriodos = (columna) => {
        if (orden.column === columna) {
            setOrden({ ...orden, direction: orden.direction === 'asc' ? 'desc' : 'asc' });
        } else {
            setOrden({ column: columna, direction: 'asc' });
        }
    };

    const getSortedData = () => {
        return [...periodos].sort((a, b) => {
            if (a[orden.column] < b[orden.column]) {
                return orden.direction === 'asc' ? -1 : 1;
            }
            if (a[orden.column] > b[orden.column]) {
                return orden.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
    };

    const openModal = (idMatricula) => {
        const periodo = periodos.find(p => p.identificador === idMatricula);
        if (!lecturasPorPeriodo[periodo._id]) {
            fetchAndFilterLecturas(periodo);
        }

        setModalOpen(prevState => ({
            ...prevState,
            [idMatricula]: true,
            periodosMatricula: periodo
        }));
    };

    const closeModal = (idMatricula) => {
        setModalOpen(prevState => ({
            ...prevState,
            [idMatricula]: false
        }));
    };

    const sortedPeriodos = getSortedData();

    return (
        <div className='listado'>
            <h2 className='title-listado'>Listado de Periodos</h2>
            
            <form className='registro-fecha' onSubmit={handleSearch}>
                <label>Fecha de inicio:
                   <br /><input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} /><br />
                   <p className='error'>{errorFecha}</p>
                   <label className='input-mostrar'><input 
                       type="checkbox"
                       onChange={(e) => setSingleDay(e.target.checked)}
                       checked={singleDay}
                   />Mostrar por un día</label>
                </label>
                <label>Fecha de fin:
                    <br /><input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} disabled={singleDay} />
                    <p className='error'>{errorFechaFin}</p>
                </label>
                <button type='button' className='mostrar-todos' onClick={fetchPeriodos} >Todos los datos</button>                
                <button type="submit">Buscar</button>
            </form>
            
            

            <table className='table-listado'>
                {error && <p>{error}</p>}
                <thead>
                    <tr className='tr-header-listado'>
                        <th className='th-header-listado' onClick={() => ordenarPeriodos('identificador')}>Identificador</th>
                        <th className='th-header-listado' onClick={() => ordenarPeriodos('zona')}>Zona</th>
                        <th className='th-header-listado' onClick={() => ordenarPeriodos('startTime')}>Inicio</th>
                        <th className='th-header-listado' onClick={() => ordenarPeriodos('endTime')}>Fin</th>
                        <th className='th-header-listado block'>Información</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedPeriodos.map(periodo => (
                      <tr className='tr-body-listado' key={periodo._id}>
                          <td>{periodo.identificador}</td>
                          <td>{periodo.zona}</td>
                          <td>{formatDateStr(periodo.startTime) + ', ' + formatTimeStr(periodo.startTime)}</td>
                          <td>{formatDateStr(periodo.endTime) + ', ' + formatTimeStr(periodo.endTime)}</td>
                          <td>
                              <button className='icon-mostrar' onClick={() => openModal(periodo.identificador)}>
                                  <FontAwesomeIcon icon={faEye} />
                              </button>
                              {modalOpen[periodo.identificador] && (
                                  <Modal
                                      isOpen={modalOpen[periodo.identificador]}
                                      onClose={() => closeModal(periodo.identificador)}
                                      matricula={periodo.identificador}
                                      lecturas={lecturasPorPeriodo[periodo._id]}
                                      periodo={periodo}
                                  />
                              )}
                          </td>
                      </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ListarTrabajos;