import React, { useState, useEffect } from 'react';
import { Global } from '../../../helpers/Global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export const Listado = () => {
    const [lecturas, setLecturas] = useState([]);
    const [cargando, setCargando] = useState(true);
    const [orden, setOrden] = useState({ column: null, direction: 'asc' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1); // Total de páginas
    const itemsPerPage = 100; // Número de elementos por página
    const [nombresTag, setNombresTag] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const nombres = await obtenerTagNombre();
            setNombresTag(nombres);
        };
        fetchData();
    }, []);

    useEffect(() => {
        if (Object.keys(nombresTag).length !== 0) {
            conseguirLecturas(nombresTag, currentPage);
        }
    }, [currentPage, nombresTag]);
    

    const obtenerTagNombre = async () => {
        const url = Global.url + "tag/listado";
        let peticion = await fetch(url, {
            method: "GET",
        });

        const datos = await peticion.json();

        if (datos.status === "success") {
            const objNombres = datos.tags.reduce((acc, tag) => {
                acc[tag.tagId] = tag.nombre;
                return acc;
            }, {});
            return objNombres;
        }
        return {};
    };

    const conseguirLecturas = async (nombres, page) => {
        setCargando(true);
        const url = `${Global.url}lectura/listado?page=${page}&limit=${itemsPerPage}`;
        let peticion = await fetch(url, {
            method: "GET",
        });
    
        const datos = await peticion.json();

        console.log(datos.status);


        if (datos.status === "success") {
            const lecturasConNombres = datos.lecturas.map(lectura => {            
                const tagNombre = nombresTag[lectura.tagId] || lectura.tagId;
                const zona = Array.isArray(lectura.data.zones) && lectura.data.zones.length === 2 && lectura.data.zones[0] === "Taller"
                    ? lectura.data.zones[1]
                    : lectura.data.zones.join(', ');

 
                return {
                    ...lectura,
                    tagId: tagNombre,
                    zona: zona
                };
            });
            setLecturas(lecturasConNombres);
            setTotalPages(datos.totalPages);

        } else {
            setLecturas([]);
            setTotalPages(1);
        }
        setCargando(false);

    };
    

    const ordenarLecturas = (columna) => {
        const nuevaDireccion = orden.column === columna && orden.direction === 'asc' ? 'desc' : 'asc';
        setOrden({ column: columna, direction: nuevaDireccion });
        setLecturas(prevLecturas => {
            return [...prevLecturas].sort((a, b) => {
                let aValue = a[columna];
                let bValue = b[columna];

                if (typeof aValue === 'string' && typeof bValue === 'string') {
                    aValue = aValue.toLowerCase();
                    bValue = bValue.toLowerCase();
                }

                if (aValue < bValue) {
                    return nuevaDireccion === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return nuevaDireccion === 'asc' ? 1 : -1;
                }
                return 0;
            });
        });
    };

    const isValidDate = (date) => {
        return !isNaN(Date.parse(date));
    };

    const formatDateStr = (date) => {
        return isValidDate(date) ? new Date(date).toISOString().split('T')[0] : 'Invalid Date'; //0000-00-00
    };

    const formatTimeStr = (date) => {
        return isValidDate(date) ? new Date(date).toISOString().split('T')[1].substring(0, 8) : 'Invalid Time'; //00:00:00
    };

    const handlePageChange = async (newPage) => {
        if (newPage > 0 && newPage <= totalPages && newPage !== currentPage) {
            setCurrentPage(newPage);
            console.log(newPage)
            await conseguirLecturas(nombresTag, newPage);  // Asegúrate de que esto se llama después de actualizar la página
        }
    };
    const indexOfLastItem = Math.min(currentPage * itemsPerPage, lecturas.length);
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = lecturas.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div>
            {cargando ? <h3>Cargando, esta cadena puede tardar unos minutos...</h3> : (
                lecturas.length > 0 ? (
                    <>
                        <table className='table-listado'>
                            <thead>
                                <tr className='tr-header-listado'>
                                    <th className='th-header-listado' rowSpan="2" onClick={() => ordenarLecturas('tagId')}>Identificador</th>
                                    <th className='th-header-listado' rowSpan="2" onClick={() => ordenarLecturas('zona')}>Zona</th>
                                    <th className='th-header-listado' rowSpan="2" onClick={() => ordenarLecturas('date')}>Fecha registro</th>
                                    <th className='th-header-listado block' colSpan="2">Coordenadas</th>
                                </tr>
                                <tr className='tr-header-listado'>
                                    <th className='th-header-listado block'>Eje X</th>
                                    <th className='th-header-listado block'>Eje Y</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map(lectura => (
                                    <tr className='tr-body-listado' key={lectura._id}>
                                        <td>{lectura.tagId}</td>
                                        <td>{lectura.zona}</td>
                                        <td>{formatDateStr(lectura.date) + ', ' + formatTimeStr(lectura.date)}</td>
                                        <td>{lectura.data.coordinates.x}</td>
                                        <td>{lectura.data.coordinates.y}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <div className='paginador'>
                            <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                              <FontAwesomeIcon icon={faChevronLeft} /> 
                            </button>
                            <span>Pag {currentPage} de {totalPages}</span>
                            <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                               <FontAwesomeIcon icon={faChevronRight} /> 
                            </button>
                        </div>
                    </>
                ) : (
                    <h1>No hay artículos</h1>
                )
            )}
        </div>
    );
};
