// Mosquito2.js
import React, { useEffect, useState } from 'react';
import mqtt from 'mqtt';
import Plano from './Plano';
import MapComponent from './pages/mapa/Mapa';

const Mosquito2 = () => {
  const [coordinates, setCoordinates] = useState({ x: 50, y: 50, z: 1000 });

  useEffect(() => {
    const tenant_id = "6528fc86154f92783b692c9a";
    const api_key = "23310ceb-e09d-4427-969c-79e157f999b1";

    const host = "wss://mqtt.cloud.pozyxlabs.com:443";
    const topic = tenant_id + "/tags";
    const username = tenant_id;
    const password = api_key;

    const options = {
      username: username,
      password: password,
    };

    const client = mqtt.connect(host, options);

    client.on('connect', () => {
      console.log('Conectado al broker MQTT');
      client.subscribe(topic, (err) => {
        if (!err) {
          console.log(`Suscrito al tema: ${topic}`);
        }
      });
    });

    client.on('message', (topic, payload) => {
      const data = payload.toString();
      console.log(`Nueva trama de datos: ${data}`);

      try {
        const parsedData = JSON.parse(data);
        console.log('Datos analizados:', parsedData[0]);

        if (parsedData[0]?.data && parsedData[0].data.coordinates) {
          let nuevaX = Math.round(parsedData[0].data.coordinates.x /10)
          let nuevaY = Math.round(parsedData[0].data.coordinates.y /10)
          setCoordinates({x:nuevaX,y:nuevaY});
        }
      } catch (error) {
        console.error('Error al analizar la cadena JSON:', error);
      }
    });

    return () => {
      client.end();
    };
  }, []);

  return (
    <div>
      {console.log(coordinates)}
      <h1>Trama de datos recibida:</h1>
      <div>
        {/* Renderizar MapComponent con las coordenadas actualizadas */}
        {coordinates.x} {coordinates.y}
      </div>
    </div>
  );
};

export default Mosquito2;
