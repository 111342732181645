import logo from '../../../img/vect.svg';
import React from 'react'
import { Nav } from './Nav';

export const Header = () => {
    return (
        <>
            <div className='header'>
                <div className="logo">
                    <img src={logo} alt='logo' />
                </div>
                <h1> Proyecto Reyes</h1>
            </div>
        </>
    )
}
