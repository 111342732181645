import React, { useState } from 'react'
import { useForm } from '../../hooks/useForm'
import { Global } from '../../helpers/Global';
import useAuth from '../../hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

export const Login = () => {

  const { formulario, cambiado } = useForm();
  const [logeado, setLogeado] = useState("no_logeado");

  const {setAuth} = useAuth();

  const logUser = async (e) => {
    e.preventDefault();

    // Captura de los datos del formulario
    let userToLogin = formulario;

    // Petición al backend
    const request = await fetch(Global.url + 'user/login', {
      method: "POST",
      body: JSON.stringify(userToLogin),
      headers: {
        "Content-Type": "application/json"
      }
    });

    // Captura de la petición
    const data = await request.json();
    if (data.status === "success") {

      // Persistir los datos en el navegador
      localStorage.setItem("token", data.token);
      localStorage.setItem("user", JSON.stringify(data.user));
      setLogeado("logeado");

      // Fijamos los datos en el Auth
      setAuth(data.user);

      // Redireccion
      setTimeout(() => {
        window.location.reload();
      }, 1500);

    } else {
      setLogeado("error");
    }

  }

  return (
    <>
    <div className='layout_identificarse'>
      <div>
        <h2>Iniciar sesión</h2>
        <strong>{logeado === "logeado" ? "Usuario identificado correctamente" : ""}</strong>
        <strong>{logeado === "error" ? "Error en la identificación " : ""}</strong>
      </div>

      <div className='content-form'>
        <form className='form-login' onSubmit={logUser}>

          <div className='form-group'>
            {/* <label className='label-layout' htmlFor='email'>Email</label><br/> */}
            <FontAwesomeIcon className='icon-login' icon={faUser} />
            <input className='input-layout' type="email" name='email' placeholder="Email" onChange={cambiado} />
          </div>

          <div className='form-group'>
            {/* <label className='label-layout' htmlFor='password'>Contraseña</label><br/> */}
            <FontAwesomeIcon className='icon-login' icon={faLock} />
            <input className='input-layout' type="password" placeholder="Password" name='password' onChange={cambiado} />
          </div>
          <div className=''>
            <Link to='/registro' className=' btn btn-registrate'>Regístrate</Link>
            <input type='submit' value="Identificate" className='btn btn-login' />
          </div>
          
        </form>

      </div>
    </div>
      
    </>
  )
}