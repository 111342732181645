import React, { useState } from 'react';
import { Global } from '../../../helpers/Global';

function AgregarTag() {
  const [tagId, setTagId] = useState('');
  const [nombre, setNombre] = useState('');
  const [imagenUrl, setImagenUrl] = useState('');
  const [mostrarPlaceholder, setMostrarPlaceholder] = useState(true); // Nuevo estado para controlar la visualización del placeholder

  // Opciones de ejemplo para imagenUrl
  const opcionesImagenUrl = [
    { label: "Trabajador", value: "mecanico" },
    { label: "Carrito de herramientas", value: "carro" },
    { label: "Recogedor de aceite", value: "aceite" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    const tagData = { tagId, nombre, imagenUrl };

    try {
      const response = await fetch((Global.url + 'tag/insertar'), {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(tagData),
      });

      if (response.ok) {
        alert('Tag añadido correctamente');
        setTagId('');
        setNombre('');
        setImagenUrl('');
        setMostrarPlaceholder(true); // Restablecer el placeholder cuando se envía el formulario
      } else {
        alert('Error al añadir el tag');
      }
    } catch (error) {
      console.error('Error al añadir el tag:', error);
      alert('Error al añadir el tag');
    }
  };

  return (
    <>
      <form className='form-agregar-tag registro-fecha' onSubmit={handleSubmit}>
        <div>
          <label htmlFor="tagId">Tag ID:</label>
          <input
            type="text"
            id="tagId"
            value={tagId}
            onChange={(e) => setTagId(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="nombre">Nombre:</label>
          <input
            type="text"
            id="nombre"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="imagenUrl">Imagen:</label>
          <select
            id="imagenUrl"
            value={imagenUrl}
            onChange={(e) => {
              setImagenUrl(e.target.value);
              setMostrarPlaceholder(false); // Eliminar el placeholder una vez que se selecciona una opción
            }}
            required
          >
            {mostrarPlaceholder && <option value="">Seleccione una imagen</option>}
            {opcionesImagenUrl.map((opcion) => (
              <option key={opcion.value} value={opcion.value}>
                {opcion.label}
              </option>
            ))}
          </select>
        </div>
        <button type="submit">Añadir Tag</button>
      </form>
    </>
  );

}
export default AgregarTag;
