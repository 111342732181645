import React, { useState } from 'react';
import { Global } from '../helpers/Global';

const Matricula = () => {
  const [identifier, setIdentifier] = useState('');
  const [periodStart, setPeriodStart] = useState(null);
  const [periodEnd, setPeriodEnd] = useState(null);

  const handleIdentifierChange = (e) => {
    setIdentifier(e.target.value);
  };

  const handleStartPeriod = () => {
    const now = new Date();
    setPeriodStart(now);
    setPeriodEnd(null); // Resetea el final del periodo
    console.log(now);
    console.log(identifier);
  };

  const handleEndPeriod = () => {
    const now = new Date();
    setPeriodEnd(now); // Guarda la hora actual como fin
    console.log(now);
    console.log(identifier);
  };

  const submitPeriod = async () => {
    console.log('Enviando al backend:', { identifier, periodStart, periodEnd });
    

    try {
      const response = await fetch(Global.url +'matricula/inserta', { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          identificador: identifier,
          startTime: periodStart.getTime(),
          endTime: periodEnd.getTime(),
        }),
      });

      if (!response.ok) {
        throw new Error('Respuesta del servidor no es OK');
      }

      const responseData = await response.json();
      console.log('Respuesta del servidor:', responseData);
      // Aquí podrías manejar la respuesta del servidor, como mostrar un mensaje de éxito
    } catch (error) {
      console.error('Error al enviar datos al servidor:', error);
      // Aquí podrías manejar el error, como mostrar un mensaje de error
    }
  };

  return (
    <div>
      <input
        type="text"
        value={identifier}
        onChange={handleIdentifierChange}
        placeholder="Ingrese la matrícula del coche"
      />
      <button onClick={handleStartPeriod}>Iniciar Período</button>
      <button onClick={handleEndPeriod} disabled={!periodStart}>Finalizar Período</button>
      <button onClick={submitPeriod} disabled={!periodEnd}>Enviar Período</button>

      {periodStart && <p>Periodo iniciado: {periodStart.toLocaleString()}</p>}
      {periodEnd && <p>Periodo finalizado: {periodEnd.toLocaleString()}</p>}
    </div>
  );
};

export default Matricula;

