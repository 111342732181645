import React, { useState, useEffect, createContext } from 'react';
import { Global } from '../helpers/Global';
import  { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

    const [auth, setAuth] = useState({});


    useEffect(() => {
        authUser();
    }, []);

    const isTokenExpired = (token) => {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decoded.exp < currentTime;
    };

    const authUser = async () => {
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user");
    
        if (!token || !user || isTokenExpired(token)) {
            console.log("Token expirado")
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            setAuth({});
            return false;
        }
    
        const userObj = JSON.parse(user);
        const userId = userObj.id;
    
        const request = await fetch(Global.url + "user/profile/" + userId, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: token,
            },
        });
    
        const data = await request.json();
    
        if (data && data.user) {
            setAuth(data.user);
        } else {
            // En caso de error al obtener el usuario, también limpiamos
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            setAuth({});
        }
    };    

    const changePassword = async (currentPassword, newPassword) => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token available");
            return;
        }

        try {
            const response = await fetch(Global.url + "user/cambiar-contrasenia", {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `${token}`
                },
                body: JSON.stringify({ currentPassword, newPassword })
            });
            console.log(response)
    
            if (!response.ok) {
                const errorResponse = await response.json();
                console.error("Error al cambiar la contraseña:", errorResponse.message);
                throw new Error('Error al cambiar la contraseña');
            }
    
            const result = await response.json();
            if(result.status === "success") {
                console.log("Contraseña actualizada con éxito:", result);
                return true;
            } else {
                console.error("Error en la actualización:", result.message);
                return false;
            }
        } catch (error) {
            console.error("Error en la solicitud:", error.message);
            return false;
        }
    };    
    
    return (
        <AuthContext.Provider
            value={{
                auth,
                setAuth,
                changePassword
             }}
        > {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;