import React from 'react'
import Mosquito from '../Mosquito'
import PanelMapa from '../PanelMapa'
import { Plano } from '../Plano'
import Mosquito2 from '../Mosquito2'
import Mosquito3 from './mapa/FlujoMapa'
import Matricula from '../Matricula'
import Matricula2 from '../Matricula2'
import Matricula3 from '../Matricula3'
import ListarPeriodos from './trabajos/ListaTrabajos'

export const Inicio = () => {
  return (
    <div>
      <h1>Inicio</h1>
    
      
     
      
      
    </div>
  )
}
