import React, { useEffect, useState } from 'react';
import mqtt from 'mqtt';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css'
import plano from '../img/planoTaller.png';

const PanelMapa = () => {
  const [coordinates, setCoordinates] = useState([]);
  const [mapCenter, setMapCenter] = useState([0, 0]);

  useEffect(() => {
    const tenant_id = "6528fc86154f92783b692c9a"
    const api_key = "23310ceb-e09d-4427-969c-79e157f999b1"

    const host = "wss://mqtt.cloud.pozyxlabs.com:443";
    const topic = tenant_id + "/tags";
    const username = tenant_id;
    const password = api_key;

    const options = {
      username: username,
      password: password,
    };

    const client = mqtt.connect(host, options);

    client.on('connect', () => {
      console.log('Conectado al broker MQTT');
      client.subscribe(topic, (err) => {
        if (!err) {
          console.log(`Suscrito al tema: ${topic}`);
        }
      });
    });

    client.on('message', (topic, payload) => {
      const data = payload.toString();
      console.log(`Nueva trama de datos: ${data}`);

      // Intentar analizar la cadena JSON
      try {
        const parsedData = JSON.parse(data);
        const { success, coordinates: newCoordinates } = parsedData[0].data;

        if (success) {
          // Calcular el punto central
          const lat1 = -10100;
          const lon1 = 3850;
          const lat2 = 13700;
          const lon2 = -6700;

          const lat_central = (lat1 + lat2) / 2;
          const lon_central = (lon1 + lon2) / 2;

          setMapCenter([lat_central, lon_central]);

          // Actualizar las coordenadas en el estado
          setCoordinates((prevCoordinates) => [...prevCoordinates, newCoordinates]);
        }
      } catch (error) {
        console.error('Error al analizar la cadena JSON:', error);
      }
    });

    return () => {
      client.end();
    };
  }, []);

  return (
    <div>
      <h1>Coordenadas recibidas:</h1>
      <div className='leaflet-container'>
        <MapContainer
          center={mapCenter}
          zoom={0}
          style={{ height: '512px', width: '1053px' }}
        >
          <TileLayer
            url={plano}
            tileSize={512}
            zoomOffset={0}
          />

          {coordinates.map((coord, index) => (
            <Marker key={index} position={[coord.x, coord.y]}>
              <Popup>Elemento {index + 1}</Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </div>
  );
};

export default PanelMapa;

