import React, {useState, useEffect } from 'react';
import { Global } from '../../../helpers/Global';
import { Link, useParams, useNavigate  } from 'react-router-dom';



export const Actualizartag2 = ()  => {

  const [tag, setTag] = useState(null);
  const {tagId, tagNombre, tagIMG} = useParams(); 
  const [error, setError] = useState('');
  const [datosModificados, setDatosModificados] = useState({
    nombre: tagNombre || '',
    img: tagIMG || opcionesImagenUrl[0].value
  });
  const [opcionesImagenUrl, setOpcionesImagenUrl] = useState([]);
  const [imagenUrlSeleccionada, setImagenUrlSeleccionada] = useState('');
  const navigate = useNavigate();


  //Obtenemos los datos de la bd
  useEffect(() => {
    const fetchTagIMG = async () => {
        try {
            const response = await fetch(Global.url + 'tag/listado'); 
            
            if (!response.ok) throw new Error('La respuesta de la API a la base de datos no es ok');

            const data = await response.json();
            const uniqueUrls = [...new Set(data.tags.map(tag => tag.imagenUrl))];
            setOpcionesImagenUrl(uniqueUrls.map(url => ({ label: url, value: url })));
          
        } catch (error) {
            setError('No se pudieron cargar los datos: ' + error.message);
        }
    };

    fetchTagIMG();
}, []);

  useEffect(() => {
    setTag(tagId);
  }, []);

  const handleChange = (e) => {
    setImagenUrlSeleccionada(e.target.value);

    const { name, value } = e.target;
    setDatosModificados(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  
  const handleUpdate = (e) => {
    e.preventDefault();

    const datosNuevos = {
      nombre: datosModificados.nombre,
      imagenUrl: datosModificados.img
    }

    actualizarTag (tag, datosNuevos);
    alert("Los datos se han actualizado");
    navigate('/app/listatags');
  };

  const actualizarTag = async(tag, datosNuevos) => {
    console.log(tag + ' / ' + datosNuevos);
    try {
      const respuesta = await fetch(`${Global.url}tag/actualizar/${tag}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(datosNuevos)
      });
      console.log(respuesta)
      if(!respuesta.ok)throw new Error('No se ha conectado a la API')
      else{
        const datosActualizados = await respuesta.json();
        console.log(datosActualizados + "Los datos se han actualizado");
    
      }
    }catch(error) {
      console.error('Error!', error);
    };

  };

  return (
    <>
      <form className='form-agregar-tag registro-fecha' onSubmit={handleUpdate}>

        <div>
          <label>Nombre:</label>
          <input
            type="text"
            id='nombre'
            value={datosModificados.nombre}
            onChange={handleChange}
            name='nombre'
          />
        </div>

        <div className='select-img'>
          <label>Imagen URL:</label><br />
          <select onChange={handleChange} name='img' defaultValue={imagenUrlSeleccionada}>
            {opcionesImagenUrl.map(option => (
              <option key={option.value} value={option.value}>{option.label}</option>
            ))}
          </select>
        </div>
        <Link className='btn btn-atras' to='/app/listatags'>Atrás</Link>
        <input type="submit" value="Actualizar"/>

      </form>
    </>
  );
};

export default Actualizartag2;
