import React, { useState, useEffect } from 'react';
import { Global } from '../../../helpers/Global';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Modal } from './Modal';


export const ListaPorMatricula = () => {
  const [periodos, setPeriodos] = useState([]);
  const [lecturasConteo, setLecturasConteo] = useState({});
  const [matricula, setMatricula] = useState('');
  const [error, setError] = useState('');
  const [cargando, setCargando] = useState(false);
  const [orden, setOrden] = useState({ column: null, direction: 'asc' });
  const [modalOpen, setModalOpen] = useState({});
  const [lecturasPorPeriodo, setLecturasPorPeriodo] = useState({});

  const ordenarPeriodos = (columna) => {
    if (orden.column === columna) {
      setOrden({ ...orden, direction: orden.direction === 'asc' ? 'desc' : 'asc' });
    } else {
      setOrden({ column: columna, direction: 'asc' });
    }
  };
  const getSortedData = () => {
    return [...periodos].sort((a, b) => {
      if (a[orden.column] < b[orden.column]) {
        return orden.direction === 'asc' ? -1 : 1;
      }
      if (a[orden.column] > b[orden.column]) {
        return orden.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const fetchAndFilterLecturas = async (periodo) => {
    const startDateStr = formatDateStr(periodo.startTime);
    const startTimeStr = formatTimeStr(periodo.startTime);
    const endDateStr = formatDateStr(periodo.endTime);
    const endTimeStr = formatTimeStr(periodo.endTime);

    const url = `${Global.url}lectura/listadofecha?startDate=${startDateStr}&startTime=${startTimeStr}&endDate=${endDateStr}&endTime=${endTimeStr}`;

    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Error fetching data: Status ${response.status}`);
        }

        const data = await response.json();

        if (data.lecturas && data.lecturas.length > 0) {
            const lecturasFiltradas = data.lecturas.filter(lectura =>
                lectura.data && lectura.data.zones && lectura.data.zones.includes(periodo.zona)
            );

            setLecturasPorPeriodo(prevState => ({
                ...prevState,
                [periodo._id]: lecturasFiltradas
            }));
        } else {
            console.log('No lecturas found or empty lecturas array.');
        }
    } catch (error) {
        console.error('Failed to fetch or process lecturas:', error);
    }
  };  

  const formatDateStr = (date) => {
    return new Date(date).toISOString().split('T')[0];  // Retorna 'YYYY-MM-DD'
  };

  const formatTimeStr = (date) => {
    return new Date(date).toISOString().split('T')[1].substring(0, 8);  // Retorna 'HH:MM:SS'
  };

  const fetchLecturasPorPeriodo = async (periodo) => {
    const convertTimestampToDateStr = timestamp => new Date(timestamp).toISOString().split('T')[0];
    const convertTimestampToTimeStr = timestamp => new Date(timestamp).toISOString().split('T')[1].substring(0, 8);

    const startDateStr = convertTimestampToDateStr(periodo.startTime);
    const startTimeStr = convertTimestampToTimeStr(periodo.startTime);
    const endDateStr = convertTimestampToDateStr(periodo.endTime);
    const endTimeStr = convertTimestampToTimeStr(periodo.endTime);

    const url = `${Global.url}lectura/listadofecha?startDate=${startDateStr}&startTime=${startTimeStr}&endDate=${endDateStr}&endTime=${endTimeStr}`;
    const response = await fetch(url, { method: 'GET' });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    if (data.status === 'success') {
      const zonaDelPeriodo = periodo.zona;  // La zona del periodo contra la que quieres comparar
      console.log("Zona del periodo: ", zonaDelPeriodo);

      //Filtro para comprobar que en la lectura aparezca la zona
      const lecturasFiltradas = data.lecturas.filter(lectura =>
        lectura.data && lectura.data.zones && lectura.data.zones.includes(zonaDelPeriodo)
      );

      console.log("Lecturas filtradas: ", lecturasFiltradas);
      return lecturasFiltradas;
    } else {
      console.error(data.mensaje);
      return [];
    }
  };


  const obtenerNombreTag = async(idLectura) => {
        //Obtener el nombre de cada lectura por su etiqueta
        try {
          const url = (Global.url + "tag/obtenertag/" + idLectura);
          const response = await fetch(url, { method: 'GET' });

          if(!response.ok){
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();

          if (data.status === 'success') {
            const nombreLectura = data.tags[0].nombre;
            console.log(nombreLectura)
            return nombreLectura; 
          } else {
            console.error(data.mensaje);
            return idLectura;
          }
        } catch (error) {
          console.error("Error al obtener el nombre por ID", error.mensaje)
          return idLectura;
        }
    }

    const openModal = (idMatricula) => {
      const periodo = periodos.find(p => p.identificador === idMatricula);
      if (!lecturasPorPeriodo[periodo._id]) {
          fetchAndFilterLecturas(periodo);
      }

      setModalOpen(prevState => ({
          ...prevState,
          [idMatricula]: true,
          periodosMatricula: periodo
      }));
  };

  const closeModal = (idMatricula) => {
      setModalOpen(prevState => ({
          ...prevState,
          [idMatricula]: false
      }));
  };

    const procesarLecturas = (lecturas) => {
      console.log("Procesando lecturas:", lecturas);

      const acumuladoPorTag = lecturas.reduce((acc, lectura) => {
        const tag = lectura.nombreTag; // Usando nombre en lugar de ID
        if (tag) {
          if (!acc[tag]) {
            acc[tag] = 0;
          }
          acc[tag] += 1;
        }
        return acc;
      }, {});

      Object.keys(acumuladoPorTag).forEach(tagId => {
        acumuladoPorTag[tagId] = (acumuladoPorTag[tagId] / 30).toFixed(2);
      });

      return acumuladoPorTag;
    };


const handlePeriodos = async (periodos) => {
  setCargando(true);
  try {
    const lecturasPromesas = periodos.map(periodo => 
      fetchLecturasPorPeriodo(periodo).then(async (lecturas) => {
        const nombresTags = await Promise.all(lecturas.map(lectura => obtenerNombreTag(lectura.tagId)));
        console.log(lecturas)
        const lecturasConNombres = lecturas.map((lectura, index) => ({
          ...lectura,
          nombreTag: nombresTags[index]
        }));
        return {
          periodoId: periodo._id,
          lecturas: procesarLecturas(lecturasConNombres)
        };
      })
    );


    const resultadosLecturas = await Promise.all(lecturasPromesas);

    const nuevoLecturasConteo = resultadosLecturas.reduce((acc, { periodoId, lecturas }) => {
      acc[periodoId] = lecturas;
      return acc;
    }, {});


    setLecturasConteo(nuevoLecturasConteo);
  } catch (error) {
    console.error('Error al procesar las lecturas: ', error);
  }
  setCargando(false);
};


useEffect(() => {
  console.log(lecturasConteo); // Esto mostrará el estado cada vez que cambie
}, [lecturasConteo]);
const handleSubmit = async (e) => {
  e.preventDefault();
  setError('');
  setCargando(true);
  if (matricula) {
    try {
      const response = await fetch(`${Global.url}matricula/pormatricula/${matricula}`);
      if (!response.ok) throw new Error('No se pudo obtener una respuesta satisfactoria de la API.');
      const data = await response.json();
      if (data.periodos && data.periodos.length > 0) {
        setPeriodos(data.periodos);
        await handlePeriodos(data.periodos); // Llama a la función que recupera lecturas para cada periodo
      } else {
        setPeriodos([]);
        setLecturasConteo({});
        setError('No se encontraron periodos para la matrícula proporcionada');
        setCargando(false);
      }
    } catch (error) {
      setPeriodos([]);
      setLecturasConteo({});
      setError('No se pudieron cargar los periodos: ' + error.message);
      setCargando(false);
    }
  } else {
    setPeriodos([]);
    setLecturasConteo({});
    setError('Por favor, introduce una matrícula para buscar.');
    setCargando(false);
  }
};
  const sortedPeriodos = getSortedData();
  const formatearContadores = (conteos) => {
    return Object.entries(conteos)
      .sort((a, b) => a[0].localeCompare(b[0]))
      .map(([tagId, conteo], index) => (
        <div key={index} style={{ margin: '4px', padding: '2px 4px', background: '#f0f0f0', borderRadius: '4px', display: 'inline-block' }}>
          {/* {tagId}: <strong>{conteo}</strong> */}
          {tagId}: <strong>{conteo}</strong>

        </div>
      ));
  };
  if (cargando) {
    return <div>Cargando datos, por favor espera...</div>;
  }
  return (
    <div className='listado-matricula'>
      <h2 className='title-listado'>Buscar Periodos por Matrícula</h2>
      <form className='matricula-form' onSubmit={handleSubmit}>
        <label htmlFor="matricula">Matrícula:</label>
        <input
          type="text"
          id="matricula"
          value={matricula}
          onChange={(e) => setMatricula(e.target.value)}
        />
        <button className='buscar-listado' type="submit">Buscar</button>
      </form>
      {error && <p>{error}</p>}
      
      {periodos.length > 0 && (
        <table className='table-listado'>
        <thead>
          <tr className='tr-header-listado'>
            <th className='th-header-listado'>Identificador</th>
            <th className='th-header-listado'>Zona</th>
            <th className='th-header-listado'>Inicio</th>
            <th className='th-header-listado'>Fin</th>
            <th className='th-header-listado block'>Lecturas</th>
            <th className='th-header-listado block'>Información</th>
          </tr>
        </thead>
        <tbody>
          {sortedPeriodos.map(periodo => (
            lecturasConteo[periodo._id] && Object.keys(lecturasConteo[periodo._id]).length > 0
              ? Object.entries(lecturasConteo[periodo._id]).map(([tagId, conteo], index) => (
                  <tr className='tr-body-listado' key={`${periodo._id}-${index}`}>
                    <td>{periodo.identificador}</td>
                    <td>{periodo.zona}</td>
                    <td>{new Date(periodo.startTime).toLocaleString()}</td>
                    <td>{new Date(periodo.endTime).toLocaleString()}</td>
                    <td>{`${tagId}: `}<strong> {conteo}min</strong></td>
                    <td>
                      <button className='icon-mostrar' onClick={() => openModal(periodo.identificador)}>
                          <FontAwesomeIcon icon={faEye} />
                      </button>
                      {modalOpen[periodo.identificador] && (
                          <Modal
                              isOpen={modalOpen[periodo.identificador]}
                              onClose={() => closeModal(periodo.identificador)}
                              matricula={periodo.identificador}
                              lecturas={lecturasPorPeriodo[periodo._id]}
                              periodo={periodo}
                          />
                      )}
                    </td>
                  </tr>
                ))
              : <tr className='tr-body-listado' key={periodo._id}>
                  <td>{periodo.identificador}</td>
                  <td>{periodo.zona}</td>
                  <td>{new Date(periodo.startTime).toLocaleString()}</td>
                  <td>{new Date(periodo.endTime).toLocaleString()}</td>
                  <td>Sin datos</td>
                </tr>
          ))}
        </tbody>
        </table>
      )}     
    </div>
  );
};

export default ListaPorMatricula;